import { Div, Collection, CollectionPrinter, Span, Link } from "@tblabs/truffle";
import { FaqDescriptionField } from "../Components/Faq/FaqDescriptionField";
import { FaqDescriptionFieldEntryView } from "../Components/Faq/FaqDescriptionFieldEntryView";
import { GalleryDescriptionField } from "../Components/Gallery/GalleryDescriptionField";
import { GalleryDescriptionFieldEntryView } from "../Components/Gallery/GalleryDescriptionFieldEntryView";
import { MarkdownDescriptionField } from "../Components/Markdown/MarkdownDescriptionField";
import { MarkdownDescriptionFieldEntryView } from "../Components/Markdown/MarkdownDescriptionFieldEntryView";
import { TableDescriptionField } from "../Components/Table/TableDescriptionField";
import { TableDescriptionFieldEntryView } from "../Components/Table/TableDescriptionFieldEntryView";
import { TextDescriptionField } from "../Components/TextDescriptionField";
import { TextDescriptionFieldEntryView } from "../Components/TextDescriptionFieldEntryView";
import { Left } from "../Components/Utils/Row";
import { DescriptionField } from "../Models/DescriptionField";
import { DescriptionFieldType } from "../Models/DescriptionFieldType";



export class DescriptionEditor extends Div
{
    constructor(collection: Collection<DescriptionField>)
    {
        super();

        this.Append(
            new CollectionPrinter(collection, x =>
            {
                switch (x.Type as DescriptionFieldType)
                {
                    case DescriptionFieldType.Markdown: return new MarkdownDescriptionFieldEntryView(x as MarkdownDescriptionField, collection);
                    case DescriptionFieldType.Faq: return new FaqDescriptionFieldEntryView(x as FaqDescriptionField, collection);
                    case DescriptionFieldType.Text: return new TextDescriptionFieldEntryView(x as TextDescriptionField, collection);
                    case DescriptionFieldType.Gallery: return new GalleryDescriptionFieldEntryView(x as GalleryDescriptionField, collection);
                    case DescriptionFieldType.Table: return new TableDescriptionFieldEntryView(x as TableDescriptionField, collection);
                    default: return new Span(`Unknown description field type "${x.Type}"`);
                }
            }),
            new Left(
                new Link("➕ Markdown").OnClick(() => collection.Add(new MarkdownDescriptionField({ Header: "Markdown" }))),
                new Link("➕ Tekst").OnClick(() => collection.Add(new TextDescriptionField({ Header: "Tekst" }))).MarginLeft(16),
                new Link("➕ Faq").OnClick(() => collection.Add(new FaqDescriptionField({ Header: "Pytania i odpowiedzi" }))).MarginLeft(16),
                new Link("➕ Galeria").OnClick(() => collection.Add(new GalleryDescriptionField({ Header: "Galeria" }))).MarginLeft(16),
                new Link("➕ Tabela").OnClick(() => collection.Add(new TableDescriptionField({ Header: "Tabela" }))).MarginLeft(16)
            )
        );
    }
}
