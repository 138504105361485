import { Collection, CollectionPrinter, Div, Link } from "@tblabs/truffle";
import { FaqDescriptionField } from "./FaqDescriptionField";
import { FaqEditEntryView } from "./FaqEditEntryView";
import { FieldEditor } from "../FieldEditor";
import { DescriptionField } from "../../Models/DescriptionField";
import { Faq } from "../../Models/Faq";


export class FaqDescriptionFieldEntryView extends FieldEditor
{
    constructor(field: FaqDescriptionField, fields: Collection<DescriptionField>)
    {
        super("FAQ", field, fields, [
            new CollectionPrinter(field.Questions, x => new FaqEditEntryView(x, field.Questions)),
            new Div().ClearBoth(),
            new Link("➕ Q&A").OnClick(() => field.Questions.Add(new Faq({ Question: "", Answer: "" }))),
        ]);
    }
}
