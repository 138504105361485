import { Collection } from "@tblabs/truffle";
import { DescriptionField } from "../../Models/DescriptionField";
import { DescriptionFieldType } from "../../Models/DescriptionFieldType";
import { RawTableDescriptionField } from "../../Models/RawTableDescriptionField";
import { TableItem } from "../../Models/TableItem";


export class TableDescriptionField extends DescriptionField
{
    public Type = DescriptionFieldType.Table;
    public Rows = new Collection<TableItem>();

    constructor(raw: Partial<RawTableDescriptionField>)
    {
        super(raw);

        this.Rows.Load(...raw.Rows?.map(x => new TableItem(x)) || []);
    }
}
