import { Button, Span, Line, UnorderedList, Spinner, Div } from "@tblabs/truffle";
import { Header } from "../Components/Utils/Header";
import { Row } from "../Components/Utils/Row";
import { SectionBox } from "../Components/ProductInfo";
import { Page } from "./Page";
import { HashLink } from "../Components/Utils/HashLink";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { Center } from "../Components/Utils/Center";
import { DescriptionFieldBox } from "../Components/DescriptionFieldBox";
import { Gallery } from "../Components/Gallery/Gallery";
import { AuthService } from "../Services/Auth/AuthService";
import { Basket } from "../Services/Basket/Basket";
import { WebsiteContentProvider } from "../Services/Content/WebsiteContentProvider";
import { Loader } from "../Components/Loader";
import { PageLogo } from "../Components/PageTop";


export class ProductPage extends Page
{
    private loader = new Loader()
    private content = new Row()

    constructor(private _content: WebsiteContentProvider, private _basket: Basket, private _user: AuthService, private productUrl: string)
    {
        super();

        this.body.Append(
            new PageLogo(),
            this.loader,
            this.content,
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._content.Get();

        const product = content.Products.Items.find(x => x.Url.value == this.productUrl);
        if (!product)
            throw new Error(`Nie znaleziono produktu`);

        const category = content.ProductCategories.Items.find(x => x.Id.value == product.CategoryId.value)
        if (!category)
            throw new Error(`Category "${product.CategoryId.value}" not found`);


        this.loader.RemoveSelf()

        this.content.Insert(
            new Header(
                new HashLink("Oferta"),
                " > ", new HashLink(category.Title.value, "category/" + category.Url.value),
                " > ", new Span(product.Title.value).Style('font-weight: 600').Color("#fe8015"),
                this._user.IsAuthorized && new HashLink("🐓Edytuj", `edit/product/${product.Url.value}`).FloatRight().FontSize(16)
            ),
            new Row(
                new Table([], [
                    [
                        new SectionBox(
                            new Gallery(product.Images),
                        ),
                        new SectionBox(
                            ...product.RentPrices.Items.filter(x => x.Visible.value)
                                .map(x => new Div().Append(
                                    new Span(`${x.Value.value}zł / ${x.Days.value} dni`).Bold().Color("#444"),
                                    new Span(`≈ ${(x.Value.value / x.Days.value).toFixed(0)}zł / dzień`).MarginLeft(8).Color("#999").FontItalic(),
                                )),
                            new Span(`ℹ️ Minimalny okres najmu: 3 dni`).FontSize(12),
                            "\n",
                            new Span(`ℹ️ Kaucja zwrotna: ${product.Deposit.value}zł`).FontSize(12),
                            new Center(
                                new Button("Wypożycz").Class("selected").Width(140)
                                    .OnClick(() => this._basket.Rent(product)),
                            ).Margin(8),
                            new Line().Margin(12),
                            new Span(`${product.SalePrice.value}zł brutto`).Bold().Color("#444"),
                            new Span(`≈ ${(product.SalePrice.value * 0.77).toFixed(2)}zł netto\n`).MarginLeft(8).Color("#999").FontItalic(),
                            new Center(
                                new Button("Kup").Class("selected").Width(140)
                                    .OnClick(() => this._basket.Buy(product)),
                            ).Margin(8),
                        ).Width(268),
                    ],
                    [
                        new SectionBox(
                            new UnorderedList(product.Features.value.split("\n")).Padding(12).PaddingLeft(32).Width(380)
                        ).Height(200),
                        new SectionBox(
                            new Span(`🟢 Produkt dostępny`),
                            new Line().Margin(8),
                            new Span(`🏅 Ocena klientów`),
                            new Center(
                                new Span(`⭐⭐⭐⭐⭐ 4.8/5`),
                            ).Margin(8),
                            new Line().Margin(8),
                            `🚚 Dostawa od 1zł w 24h\n`,
                            `🕵️‍♂️ Pomoc techniczna 24/7`)
                            .Width(268).Height(200),
                    ]
                ]
                ).MarginAuto(),

                ...product.Description.Items.map(entry => new DescriptionFieldBox(entry)),
                new Line(),
                new Center(
                    new Button("Wypożycz").Class("selected")
                    .OnClick(() => this._basket.Rent(product)),
                    new Span("lub").Margin(12).Italic(),
                    new Button("Kup").Class("selected")
                    .OnClick(() => this._basket.Buy(product)),
                ).Margin(32),
            )
        )
    }
}
