export class UserInfo
{
    public User = {
        Id: "",
        Name: ""
    }

    public App = {
        WebsiteContentStorageAccessToken: ""
    }
}
