import { UserInfo } from "./UserInfo";
import { AuthApi } from "./AuthApi";
import { Credentials } from "./Credentials";
import { UserLocalStorage } from "./UserLocalStorage";


export class AuthService
{
    private user: UserInfo | null = null;

    constructor(
        private _api: AuthApi,
        private _storage: UserLocalStorage)
    { }

    public get IsAuthorized(): boolean
    {
        return !!this.user?.User?.Id && this.user?.User?.Id != "";
    }

    public get Info(): UserInfo | null
    {
        return this.user;
    }

    public async TryLogin(password: string): Promise<boolean>
    {
        const credentials = new Credentials(password);

        const result = await this._api.GetUserInfo(credentials);
        const user = result.UserInfo;

        if (result.IsLoginSuccess && user)
        {
            this._storage.Save(user);
            this.user = user;
        }

        return result.IsLoginSuccess;
    }
    
    public Logout(): void
    {
        this.user = null;
        this._storage.Delete();
    }

    public Set(user: UserInfo): void
    {
        if (user)
        {
            this.user = user;
        }
    }

    public TryAutologin(): void
    {
        if (this._storage.IsUserAvailable)
        {
            this.Set(this._storage.User);
        }
    }
}
