import { RefString, RefNumber } from "@tblabs/truffle";


export class DynamicBasketItem
{
    public Id = "";
    public Type = "";
    public Label = new RefString();
    public Picture = "";
    public Name = "";
    public Link = "";
    public Price = "";
    public Deposit = 0;

    public Quantity = new RefNumber();
    public DiscountLabel = new RefString();
    public Cost = new RefNumber();
}
