import { HashLink } from "../Components/Utils/HashLink";


export class AdminEditButton extends HashLink
{
    constructor(url: string)
    {
        super("🐓 Edytuj", url);
        this.FloatRight().FontSize(16);
    }
}

export class AdminAddButton extends HashLink
{
    constructor(url: string)
    {
        super("🐓 Dodaj", url);
        this.FloatRight().FontSize(16);
    }
}
