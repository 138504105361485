import { Div } from "@tblabs/truffle";


export class Page extends Div
{
    protected body = new Div()

    constructor()
    {
        super();
        
        this.Append(
            this.body,
        )
    }
}
