import { Div } from "@tblabs/truffle";
import { Basket } from "../Services/Basket/Basket";
import { WebsiteContentProvider } from "../Services/Content/WebsiteContentProvider";
import { Loader } from "../Components/Loader";
import { OrderForm, OrdersClient } from "../Components/OrderForm";
import { Page } from "./Page";
import { WebsiteContent } from "../Models/WebsiteContent";


export class WebsitePage extends Page
{
    private websiteContent: WebsiteContent;
    private whenReady: (content: WebsiteContent)=>void;
    
    private loader = new Loader()
    private content = new Div()

    constructor(private __website: WebsiteContentProvider)
    {
        super();

        this.Append(
            this.loader,
            this.content,
        )
    }

    protected async OnAppend(): Promise<void>
    { 
        this.websiteContent = await this.__website.Get();
     
        this.loader.RemoveSelf()

        this.content.Append(
            this.whenReady?.(this.websiteContent)
        )
    }

    public WhenReady(handler: (content: WebsiteContent)=>void)
    {
        this.whenReady = handler;
        return this;
    }
}

export class BasketPage extends WebsitePage
{
    constructor(_website: WebsiteContentProvider, _basket: Basket)
    {
        super(_website);

        const _orders = new OrdersClient()

        this.WhenReady((websiteContent) =>
        {
            return new OrderForm(websiteContent, _basket, _orders)
        })
    }
}

// export class BasketPage extends Page
// {
//     private loader = new Loader()
//     private content = new Div()

//     constructor(private _website: WebsiteContentProvider, private _basket: Basket)
//     {
//         super();

//         this.Append(
//             this.loader,
//             this.content,
//         )
//     }

//     protected async OnAppend(): Promise<void>
//     { 
//         const content = await this._website.Get();
     
//         this.loader.RemoveSelf()

//         this.content.Append(
//             new OrderForm(content, this._basket)
//         )
//     }
// }
