import { Collection, RefString } from "@tblabs/truffle";
import { Product, RawProduct } from "./Product";
import { ProductCategory, RawProductCategory } from "./ProductCategory";
import { DescriptionField } from "./DescriptionField";
import { RawDescriptionField } from "./RawDescriptionField";


export interface RawWebsiteContent
{
    ProductCategories: RawProductCategory[];
    Products: RawProduct[];
    AboutUs: RawDescriptionField[];
    Contact: RawDescriptionField[];
    Blog: RawBlogArticle[];
}

export interface RawContactSection
{
    Form: string;
    Details: string;
}
export class ContactSection
{
    public Form = new RefString();
    public Details = new RefString();   
    
    constructor(raw: Partial<RawContactSection>)
    {
        this.Form.value = raw.Form || "";
        this.Details.value = raw.Details || "";
    }
}
export interface RawBlogArticle
{
    Title: string;
    Url: string;
    Text: string;
}
export class BlogArticle
{
    public Title = new RefString();
    public Url = new RefString();
    public Text = new RefString();

    constructor(raw: Partial<RawBlogArticle>)
    {
        this.Title.value = raw.Title || "";
        this.Url.value = raw.Url || "";
        this.Text.value = raw.Text || "";
    }
}
export class WebsiteContent
{
    public ProductCategories = new Collection<ProductCategory>();
    public Products = new Collection<Product>();
    // public AboutUs = new RefString();
    public AboutUs = new Collection<DescriptionField>();
    // public Contact = new Collection<ContactSection>();
    public Contact = new Collection<DescriptionField>();
    public Blog = new Collection<BlogArticle>();

    public get ProductCategoriesAsTitleIdObject(): object
    {
        return this.ProductCategories.Items.reduce((prev, curr: ProductCategory) => ({ ...prev, [curr.Title.value]: curr.Id.value }), {});
    }

    public CategoryByUrl(url: string): ProductCategory
    {
        const category = this.ProductCategories.Items.find(x => x.Url.value == url);

        if (!category)
            // throw new Error(`Category not found`);
            return ProductCategory.NotFound;

        return category;
    }

    public ProductByUrl(url: string): Product
    {
        const product = this.Products.Items.find(x => x.Url.value == url);

        if (!product)
            throw new Error(`Product not found`);

        return product;
    }

    public ProductsByCategory(category: ProductCategory): Product[]
    {
        return this.Products.Items.filter(x => x.CategoryId.value == category?.Id.value);
    }
}
