import { UserInfo } from "./UserInfo";


export class AuthResult
{
    public IsLoginSuccess = false;
    public ErrorMessage: string = "";
    public UserInfo: UserInfo | null = null;

    public static Failed(errorMessage: string): AuthResult
    {
        const result = new AuthResult();
        result.IsLoginSuccess = false;
        result.ErrorMessage = errorMessage;

        return result;
    }

    public static Success(info: UserInfo): AuthResult
    {
        const result = new AuthResult();
        result.IsLoginSuccess = true;
        result.ErrorMessage = "";
        result.UserInfo = info;

        return result;
    }
}
