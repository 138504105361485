import { Collection, TextInput, MultilineInput } from "@tblabs/truffle";
import { EntryView } from "../Components/EntryView";
import { BlogArticle } from "../Models/WebsiteContent";


export class BlogEntry extends EntryView<BlogArticle>
{
    constructor(entry: BlogArticle, entries: Collection<BlogArticle>)
    {
        super(entry, entries);

        this.LeftSide.Append(
            new TextInput(entry.Title).Placeholder("Tytuł"),
            new TextInput(entry.Url).Placeholder("Url"),
            new MultilineInput(entry.Text).Rows(16).Placeholder("Treść artykułu w formacie markdown")
        )
    }
}
