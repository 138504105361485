import { RefBool, RefString } from "@tblabs/truffle";
import { DescriptionFieldType } from "./DescriptionFieldType";
import { RawDescriptionField } from "./RawDescriptionField";


export abstract class DescriptionField
{
    public abstract Type: DescriptionFieldType;
    public Header = new RefString();
    public VisibleHeader = new RefBool();

    constructor(raw: Partial<RawDescriptionField>)
    {
        this.Header.value = raw.Header || "";
        this.VisibleHeader.value = raw.VisibleHeader ?? true;
    }
}
