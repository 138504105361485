import { Collection, TextInput } from "@tblabs/truffle";
import { EntryView } from "../EntryView";
import { Faq } from "../../Models/Faq";


export class FaqEditEntryView extends EntryView<Faq>
{
    constructor(faq: Faq, faqs: Collection<Faq>)
    {
        super(faq, faqs);

        this.Append(
            new TextInput(faq.Question).Placeholder("Pytanie").WidthPercent(85),
            new TextInput(faq.Answer).Placeholder("Odpowiedź").WidthPercent(85)
        )
    }
}
