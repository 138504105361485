import { Collection, MultilineInput, Div } from "@tblabs/truffle";
import { TableItem } from "../../Models/TableItem";
import { EntryView } from "../EntryView";


export class TableEditEntryView extends EntryView<TableItem>
{
    constructor(row: TableItem, rows: Collection<TableItem>)
    {
        super(row, rows);
        this.Padding(8)

        this.LeftSide.Append(
            new MultilineInput(row.Left).Placeholder("Lewa kolumna (markdown)").WidthPercent(45).FloatLeft(),
            new MultilineInput(row.Right).Placeholder("Prawa kolumna (markdown)").WidthPercent(45).FloatLeft(),
            new Div().ClearBoth(),
        )
    }
}
