import { Ref, Collection } from "@tblabs/truffle";
import { primitive } from "@tblabs/truffle/Core/primitive";


export class RefWatcher
{
    public static Watch(refs: (Ref<primitive> | Collection<any>)[], doWhenAnyRefChange: () => void)
    {
        refs.forEach(ref =>
        {
            ref.OnChange(v => doWhenAnyRefChange());
        });

        doWhenAnyRefChange();
    }
}
