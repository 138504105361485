import { Div, Label } from "@tblabs/truffle";
import { Faq } from "../../Models/Faq";


export class FaqEditEntry extends Div
{
    constructor(faq: Faq)
    {
        super();
        this.TextAlignLeft().Append(
            new Label(faq.Question).DisplayBlock().Color("#b37504"),
            new Label(faq.Answer).DisplayBlock().Margin(8)
        );
    }
}
