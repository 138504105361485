import { RefString } from "@tblabs/truffle";
import { DescriptionField } from "../Models/DescriptionField";
import { DescriptionFieldType } from "../Models/DescriptionFieldType";
import { RawTextDescriptionField } from "../Models/RawTextDescriptionField";


export class TextDescriptionField extends DescriptionField
{
    public Type = DescriptionFieldType.Text;
    public Text = new RefString();

    constructor(raw: Partial<RawTextDescriptionField>)
    {
        super(raw);
        
        this.Text.value = raw.Text || "";
    }
}
