import { Collection, MultilineInput } from "@tblabs/truffle";
import { DescriptionField } from "../../Models/DescriptionField";
import { FieldEditor } from "../FieldEditor";
import { MarkdownDescriptionField } from "./MarkdownDescriptionField";
import { Markdown } from "../Utils/Markdown";


export class MarkdownDescriptionFieldEntryView extends FieldEditor
{
    constructor(description: MarkdownDescriptionField, descriptions: Collection<DescriptionField>)
    {
        super("Markdown", description, descriptions, [
            new MultilineInput(description.Markdown).Rows(8).WidthPercent(50).FloatLeft().TextAlignLeft().Padding(12).Placeholder("Markdown"),
            new Markdown(description.Markdown).WidthPercent(50).FloatRight().Border(1, "#888").TextAlignLeft().Padding(12).HeightAuto(),
        ]);
    }
}
