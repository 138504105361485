"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Filters = void 0;
var Filters = /** @class */ (function () {
    function Filters() {
    }
    Filters.TextContains = function (filter, field) {
        if (Array.isArray(field)) {
            return filter.value.trim()
                ? field.some(function (f) { return f.trim().toLowerCase().includes(filter.value.trim().toLowerCase()); })
                : true;
        }
        return filter.value.trim()
            ? field.trim().toLowerCase().includes(filter.value.trim().toLowerCase())
            : true;
    };
    return Filters;
}());
exports.Filters = Filters;
