
export class Credentials
{
    AppName = "SpecteamWebsite";
    Password = "";

    constructor(password: string)
    {
        this.Password = password;
    }
}
