import { Cost } from "./Cost";


export class ReturnableCost extends Cost
{
    constructor(label: string, value: number)
    {
        super(label, value);
    }
}
