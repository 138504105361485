import { AuthQuery } from "./AuthQuery";
import { AuthResult } from "./AuthResult";
import { Credentials } from "./Credentials";
import { UserInfo } from "./UserInfo";


export class AuthApi
{
    constructor(private api: string)
    { }

    public async GetUserInfo(credentials: Credentials): Promise<AuthResult>
    {
        try
        {
            const authQuery = new AuthQuery(credentials.AppName, credentials.Password);

            const requestOptions: RequestInit = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(authQuery),
            };

            const response = await fetch(this.api, requestOptions);

            if (response.ok && response.status == 200)
            {
                const userInfo = await response.json() as UserInfo;

                return AuthResult.Success(userInfo);
            }

            return AuthResult.Failed(`Auth problem: ${response.statusText}`);
        }
        catch (ex: any)
        {
            return AuthResult.Failed('Auth or network problem: ' + ex.message);
        }
    }
}
