import { AuthService } from "../Auth/AuthService";
import { HttpResponse } from "./Models/HttpResponse";
import { IMessage } from "./Models/IMessage";
import { IStorageV3Auth } from "./Models/ISender";
import { MessagePackage } from "./Models/MessagePackage";
import { StorageResponse } from "./Models/StorageResponse";


export class OnlineStorage
{
    private auth: IStorageV3Auth = { AuthToken: "" };

    constructor(private serverUrl: string, private authKey?: string) 
    {
        if (authKey)
            this.SetAuthorizationKey({ AuthToken: authKey })
    }

    public SetAuthorizationKey(auth: IStorageV3Auth): void
    {
        this.auth = auth;
    }

    public async SendMessage(message: IMessage): Promise<StorageResponse>
    {
        let messagePackage = new MessagePackage();
        messagePackage.Message = message;
        messagePackage.Sender = this.auth;

        return await this.SendMessagePackage(messagePackage);
    }

    private async PostJSON(data: any): Promise<HttpResponse>
    {
        const httpResponse = new HttpResponse();
        const dataAsString = JSON.stringify(data);
        const response = await fetch(this.serverUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: dataAsString,
        });

        httpResponse.status = response.status;
        const json = await response.json();
        httpResponse.data = json;
        return httpResponse;
    }

    private async SendMessagePackage(messagePackage: MessagePackage): Promise<StorageResponse>
    {
        try
        {
            // console.log(`[OnlineStorage] ${JSON.stringify(messagePackage)} --> ${this.serverUrl}`);
            const response = await this.PostJSON(messagePackage);

            if (response.status == 200 && response.data.IsSuccess == true)
            {
                const result = response.data as StorageResponse;

                // console.log(`[OnlineStorage] ✅ Result: ${JSON.stringify(result.Result)}`);
                return StorageResponse.Success(result.Result);
            }
            else if (response.status == 200 && response.data.IsSuccess == false)
            {
                // console.log(`[OnlineStorage] ❌ Operation failure: ${JSON.stringify(response)}`);
                console.log(`[OnlineStorage] ❌ Operation failure: ${response.data.ErrorMessage}`);
                return StorageResponse.Fault(response.status, response.data.ErrorMessage);
            }

            console.log(`[OnlineStorage] ❌ Response: ${JSON.stringify(response)}`);
            return StorageResponse.Fault(response.status, "Non-200 Status");
        }
        catch (ex: any)
        {
            if (ex.message == 'Failed to fetch')
            {
                console.log(`[OnlineStorage] ❌ Internet connection problem: ${ex.message}`);

                return StorageResponse.Fault(ex.response?.status, ex.message);
            }

            console.log(`[OnlineStorage] ❌ Exception: ${ex.message} | HTTP ${ex.response?.status}`);
            return StorageResponse.Fault(ex.response?.status, ex.message);
        }
    }
}
