import { Div } from "@tblabs/truffle";
import { appendable } from "@tblabs/truffle/Core/appendable";


export class SectionBox extends Div
{
    constructor(...cmps: appendable[])
    {
        super();
        this.Style(`text-align: left; display: inline-block; position: relative; margin: 8px; background: #fff;
            border: 8px solid #fff`);

        this.Append(
            new Div().Append(...cmps).Style("margin: 8px; color: #222")
        )
    }
}
