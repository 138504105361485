import { RefNumber, RefString } from "@tblabs/truffle";
import { RawGalleryItem } from "./RawGalleryItem";


export class GalleryImage
{
    public ThumbnailUrl = new RefString();
    public Url = new RefString();
    public Description = new RefString();
    public XOffset = new RefNumber();
    public YOffset = new RefNumber();

    constructor(raw: Partial<RawGalleryItem>)
    {
        this.ThumbnailUrl.value = raw.ThumbnailUrl || "";
        this.Url.value = raw.Url || "";
        this.Description.value = raw.Description || "";
        this.XOffset.value = raw.XOffset || 0;
        this.YOffset.value = raw.YOffset || 0;
    }
}
