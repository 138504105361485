import { RefString } from "@tblabs/truffle";


export class Faq
{
    public Question = new RefString();
    public Answer = new RefString();

    constructor(raw: RawFaq)
    {
        this.Question.value = raw.Question;
        this.Answer.value = raw.Answer;
    }
}
