import { Div, Ref, Span } from "@tblabs/truffle";
import showdown from "showdown";

showdown.extension('targetlink', function ()
{
    return [{
        type: 'html',
        regex: /(<a [^>]+?)(>.*<\/a>)/g,
        replace: '$1 target="_blank"$2'
    }];
});


//
// ADD
//
//      <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/uikit@3.11.1/dist/css/uikit.min.css" />
//
// TO MAKE IT WORK
//
export class Markdown extends Div
{
    private options = { tables: true, tasklists: true, underline: true, strikethrough: true, backslashEscapesHTMLTags: true };
    private converter = new showdown.Converter(this.options);

    constructor(markdown?: string | Ref<string>)
    {
        super("markdown");
        this.TextAlignLeft()

        if (markdown instanceof Ref)
        {
            markdown.OnChange(md => this.Render(md), true)
        }
        else
        {
            this.Render(markdown);
        }
    }

    private Render(markdown: string | Ref<string> | undefined)
    {
        const html = this.converter.makeHtml(markdown);
        this.Html(html);
    }
}