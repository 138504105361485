import { RefString } from "@tblabs/truffle";

export interface RawProductCategory
{
    Id: string;
    Url: string;
    Title: string;
    Subtitle: string;
    Image: string;
    Description: string;
}

export class ProductCategory
{
    public Id = new RefString();
    public Url = new RefString();
    public Title = new RefString();
    public Subtitle = new RefString();
    public Image = new RefString();
    public Description = new RefString();

    constructor(raw: Partial<RawProductCategory>)
    {
        this.Id.value = raw.Id || "";
        this.Url.value = raw.Url || "";
        this.Title.value = raw.Title || "";
        this.Subtitle.value = raw.Subtitle || "";
        this.Image.value = raw.Image || "";
        this.Description.value = raw.Description || "";
    }

    public static get NotFound(): ProductCategory
    {
        return new ProductCategory({ Id: "", Title: "(brak kategorii)" });
    }
}
