import { FeaturesInfo } from "../Components/RentalInfo";
import { Page } from "./Page";
import { Header } from "../Components/Utils/Header";
import { Row } from "../Components/Utils/Row";
import { ProductCategoryBox } from "../Components/ProductCategoryBox";
import { WebsiteContentProvider } from "../Services/Content/WebsiteContentProvider";
import { Loader } from "../Components/Loader";
import { PageLogo } from "../Components/PageTop";
import { AuthService } from "../Services/Auth/AuthService";
import { AdminEditButton } from "../Components/AdminEditButton";
import { DescriptionFieldBox } from "../Components/DescriptionFieldBox";


export class MainPage extends Page
{
    private loader1 = new Loader()
    private content = new Row()

    constructor(private _content: WebsiteContentProvider, private _auth: AuthService)
    {
        super();

        this.body.Append(
            new PageLogo(),
            new FeaturesInfo(),
            new Header("Nasza oferta", _auth.IsAuthorized && new AdminEditButton('edit/category')),
            this.loader1,
            this.content,
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._content.Get();
        const categories = content.ProductCategories.Items;

        this.loader1.RemoveSelf()

        this.content.Insert(
            ...categories.map(x =>
            {
                let priceFor = "⚠️ brak produktów";
                const firstProduct = content.ProductsByCategory(x)?.[0];
                if (firstProduct)
                {
                    const rent = firstProduct.RentPrices.Items[0];
                    const price = Math.round(rent.Value.value / rent.Days.value);
                    priceFor = `${price}zł / ${rent.Days.value} dni`;
                }
                return new ProductCategoryBox(x, priceFor)
            }),
            this._auth.IsAuthorized && new AdminEditButton('edit/page'),
            ...content.AboutUs.Items.map(entry => new DescriptionFieldBox(entry)),
        )
    }
}