import { RefNumber, RefString } from "@tblabs/truffle";
import { RawOpinion } from "./RawOpinion";


export class Opinion
{
    public Rate = new RefNumber();
    public Adventages = new RefString();
    public Disadventages = new RefString();
    public Comment = new RefString();
    public Author = new RefString();

    constructor(raw: Partial<RawOpinion>)
    {
        this.Rate.value = raw.Rate || (-1);
        this.Adventages.value = raw.Adventages || "";
        this.Disadventages.value = raw.Disadventages || "";
        this.Comment.value = raw.Comment || "";
        this.Author.value = raw.Author || "";
    }
}
