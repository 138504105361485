
export enum ReturnForm
{
    Unset = "Unset",
    Package = "Package",
    Personal = "Personal",
    Taxi = "Taxi",
    Rentomat = "Rentomat",
}

export const ReturnFormValueToLabel = {
    [ReturnForm.Unset]: "(nie wybrano formy zwrotu)",
    [ReturnForm.Package]: "Paczka",
    [ReturnForm.Personal]: "Zwrot osobisty",
    [ReturnForm.Taxi]: "Taksówka",
    [ReturnForm.Rentomat]: "Rentomat",
}