import { Collection, CollectionPrinter, Div, Link } from "@tblabs/truffle";
import { GalleryImage } from "../../Models/GalleryImage";
import { GalleryDescriptionField } from "./GalleryDescriptionField";
import { GalleryEditEntryView } from "./GalleryEditEntryView";
import { DescriptionField } from "../../Models/DescriptionField";
import { FieldEditor } from "../FieldEditor";


export class GalleryDescriptionFieldEntryView extends FieldEditor
{
    constructor(field: GalleryDescriptionField, fields: Collection<DescriptionField>)
    {
        super("Galeria", field, fields, [
            new CollectionPrinter(field.Images, x => new GalleryEditEntryView(x, field.Images)),
            new Div().ClearBoth(),
            new Link("➕ Obraz").OnClick(() => field.Images.Add(new GalleryImage({ Url: "", Description: "" }))),
        ]);
    }
}
