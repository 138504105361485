import { Div } from "@tblabs/truffle";
import { Header } from "./Utils/Header";
import { Markdown } from "./Utils/Markdown";
import { DescriptionField } from "../Models/DescriptionField";
import { DescriptionFieldType } from "../Models/DescriptionFieldType";
import { TextDescriptionField } from "./TextDescriptionField";
import { MarkdownDescriptionField } from "./Markdown/MarkdownDescriptionField";
import { FaqPrinter } from "./Faq/FaqPrinter";
import { Gallery } from "./Gallery/Gallery";
import { FaqDescriptionField } from "./Faq/FaqDescriptionField";
import { GalleryDescriptionField } from "./Gallery/GalleryDescriptionField";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { TableDescriptionField } from "./Table/TableDescriptionField";


export class DescriptionFieldBox extends Div
{
    constructor(field: DescriptionField)
    {
        super();
        this.TextAlignLeft()

        if (field.VisibleHeader.value)
        {
            this.Append(
                new Header(field.Header.value)
            )
        }

        switch (field.Type)
        {
            case DescriptionFieldType.Text:
                this.Append((field as TextDescriptionField).Text.value);
                break;
            case DescriptionFieldType.Markdown:
                this.Append(new Markdown((field as MarkdownDescriptionField).Markdown.value).MarginLeftRight(8));
                break;
            case DescriptionFieldType.Faq:
                this.Append(new FaqPrinter(field as FaqDescriptionField));
                break;
            case DescriptionFieldType.Gallery:
                this.Append(new Gallery((field as GalleryDescriptionField).Images).Background("none").MarginAuto());
                break;
            case DescriptionFieldType.Table:
                const tableRows = (field as TableDescriptionField).Rows.Items.map(x => [new Markdown(x.Left.value), new Markdown(x.Right.value)]);
                this.Append(
                    new Table([], tableRows).Background("none").Class("uk-table").WidthAuto().MarginAuto()
                )
                break;
        }
    }
}
