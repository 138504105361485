import { Div, Collection, Link, Ref } from "@tblabs/truffle";


export class EntryView<T> extends Div
{
    protected LeftSide = new Div().FloatLeft().WidthPercent(85).TextAlignLeft()//.Border(2, "blue")

    constructor(item: T, collection: Collection<T>)
    {
        super();
        this.PaddingBottom(0)
            .Background("#f8f8f8")
            .MarginBottom(8)
            .Border(1, "#d8d8d8")
            .BorderRadius(8);

        const entryControlSection = new Div().FloatRight().TextAlignRight().Margin(8).Width(74)//.Border(2, "red")
            .Append(
                collection.CanSwapUp(item) && new Link("⬆️")
                    .OnClick(() => collection.SwapUp(item)),
                collection.CanSwapDown(item) && new Link("⬇️")
                    .OnClick(() => collection.SwapDown(item)),
                new Link("✖️").OnClick(() => collection.Remove(item)),
            )

        this.Append(
            this.LeftSide,
            entryControlSection,
            new Div().ClearBoth(),
        )
    }
}
