import { Div } from "@tblabs/truffle";
import { Row } from "./Utils/Row";


export class FeaturesInfo extends Div
{
    constructor()
    {
        super();

        this.Append(
            new Row(
                new Div("feature").Text("BEZ UMOWY\nBEZ DOKUMENTÓW"),
                new Div("feature").Text("UBEZPIECZENIE\nOD USZKODZEŃ"),
                new Div("feature").Text("WSPARCIE\nSPECJALISTÓW"),
                new Div("feature").Text("PRZESYŁKA\nW 24H"),
                new Div("feature").Text("MINIMUM\n3 DNI W CENIE")
            ).MarginTop(16)
        )
    }
}
