import { Collection } from "@tblabs/truffle";
import { DescriptionField } from "../../Models/DescriptionField";
import { DescriptionFieldType } from "../../Models/DescriptionFieldType";
import { Faq } from "../../Models/Faq";
import { RawFaqDescriptionField } from "../../Models/RawFaqDescriptionField";


export class FaqDescriptionField extends DescriptionField
{
    public Type = DescriptionFieldType.Faq;
    public Questions = new Collection<Faq>();

    constructor(raw: Partial<RawFaqDescriptionField>)
    {
        super(raw);

        this.Questions.Load(...raw.Questions?.map(x => new Faq(x)) || []);
    }
}
