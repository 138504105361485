import { Collection, RefNumber, RefString } from "@tblabs/truffle";
import { GalleryImage } from "./GalleryImage";
import { RawOpinion } from "./RawOpinion";
import { RawGalleryImage } from "./RawGalleryImage";
import { DescriptionFieldType } from "./DescriptionFieldType";
import { DescriptionField } from "./DescriptionField";
import { RawDescriptionField } from "./RawDescriptionField";
import { RawRentPrice, RentPrice } from "./RentPrice";
import { FaqDescriptionField } from "../Components/Faq/FaqDescriptionField";
import { GalleryDescriptionField } from "../Components/Gallery/GalleryDescriptionField";
import { MarkdownDescriptionField } from "../Components/Markdown/MarkdownDescriptionField";
import { TextDescriptionField } from "../Components/TextDescriptionField";
import { RawFaqDescriptionField } from "./RawFaqDescriptionField";
import { RawGalleryDescriptionField } from "./RawGalleryDescriptionField";
import { RawMarkdownDescriptionField } from "./RawMarkdownDescriptionField";
import { RawTextDescriptionField } from "./RawTextDescriptionField";
import { Opinion } from "./Opinion";
import { RawTableDescriptionField } from "./RawTableDescriptionField";
import { TableDescriptionField } from "../Components/Table/TableDescriptionField";
import { DescriptionFieldConverter } from "../Services/Content/DescriptionFieldConverter";


export interface RawProduct
{
    Id: string;
    CategoryId: string;
    Url: string;
    Title: string;
    Subtitle: string;
    BasketName: string;
    Tags: string[];
    RentPrices: RawRentPrice[];
    SalePrice: number;
    Deposit: number;
    Features: string;
    Description: RawDescriptionField[];
    Images: RawGalleryImage[];
    Opinions: RawOpinion[];
}

export class Product
{
    public Id: string = "";
    public CategoryId = new RefString();
    public Url = new RefString();
    public Title = new RefString();
    public Subtitle = new RefString();
    public BasketName = new RefString();
    public Tags = new Collection<string>();
    public RentPrices = new Collection<RentPrice>();
    public SalePrice = new RefNumber()
    public Deposit = new RefNumber()
    public Features = new RefString();
    public Description = new Collection<DescriptionField>();
    public Images = new Collection<GalleryImage>();
    public Opinions = new Collection<Opinion>();
    public Extras = new Collection<Product>(); // TODO:

    constructor(raw: Partial<RawProduct>)
    {
        if (raw.Id)
            this.Id = raw.Id;
        if (raw.CategoryId)
            this.CategoryId.value = raw.CategoryId;
        if (raw.Url)
            this.Url.value = raw.Url;
        if (raw.Title)
            this.Title.value = raw.Title;
        if (raw.Subtitle)
            this.Subtitle.value = raw.Subtitle;
        if (raw.BasketName)
            this.BasketName.value = raw.BasketName;
        if (raw.Tags)
            this.Tags.Load(...raw.Tags);
        if (raw.RentPrices)
            this.RentPrices.Load(...raw.RentPrices.map(x => new RentPrice(x)));
        else this.RentPrices.Load(
            new RentPrice({ Days: 3, Visible: true }),
            new RentPrice({ Days: 7, Visible: true }),
            new RentPrice({ Days: 14, Visible: true }),
            new RentPrice({ Days: 30 }),
            new RentPrice({ Days: 90 }))
        if (raw.SalePrice)
            this.SalePrice.value = raw.SalePrice;
        if (raw.Deposit)
            this.Deposit.value = raw.Deposit;
        if (raw.Features)
            this.Features.value = raw.Features;
        if (raw.Description)
            this.Description.Load(...raw.Description.map(x => DescriptionFieldConverter.ToClass(x)))
        if (raw.Images)
            this.Images.Load(...raw.Images.map(x => new GalleryImage(x)))
        if (raw.Opinions)
            this.Opinions.Load(...raw.Opinions.map(x => new Opinion(x)))
    }
}
