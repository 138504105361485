import { Collection } from "@tblabs/truffle";
import { GalleryImage } from "../../Models/GalleryImage";
import { DescriptionField } from "../../Models/DescriptionField";
import { DescriptionFieldType } from "../../Models/DescriptionFieldType";
import { RawGalleryDescriptionField } from "../../Models/RawGalleryDescriptionField";


export class GalleryDescriptionField extends DescriptionField
{
    public Type = DescriptionFieldType.Gallery;
    public Images = new Collection<GalleryImage>();

    constructor(raw: Partial<RawGalleryDescriptionField>)
    {
        super(raw);
        
        this.Images.Load(...raw.Images?.map(x => new GalleryImage(x)) || []);
    }
}
