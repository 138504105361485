import { NumberInput, TextInput, Button, Select, ModalWindow, Root, MultilineInput, DestroyingPatternContentSwitcher, HashRef, Div, Label, Line, Link, Span, RefBool } from "@tblabs/truffle";
import moment from "moment";
import "moment/locale/pl";
import { MainPage } from "./Pages/MainPage";
import { CategoryPage } from "./Pages/CategoryPage";
import { ProductPage } from "./Pages/ProductPage";
import { AboutUsPage } from "./Pages/AboutUsPage";
import { ContactPage } from "./Pages/ContactPage";
import { BlogPage } from "./Pages/BlogPage";
import { BasketPage } from "./Pages/BasketPage";
import { ProductEditPage } from "./Pages/ProductEditPage";
import { CategoryEditPage } from "./Pages/CategoryEditPage";
import { PageEditPage } from "./Pages/PageEditPage";
import { WebsiteContentConverter } from "./Services/Content/WebsiteContentConverter";
import { WebsiteContentProvider } from "./Services/Content/WebsiteContentProvider";
import { OnlineStorage } from "./Services/Storage/OnlineStorage";
import { RawWebsiteContentRepo } from "./Services/Repo/RawWebsiteContentRepo";
import { AuthService } from "./Services/Auth/AuthService";
import { UserLocalStorage } from "./Services/Auth/UserLocalStorage";
import { LoginPage } from "./Services/Auth/LoginPage";
import { AuthApi } from "./Services/Auth/AuthApi";
import { BasketLocalStorage } from "./Services/Basket/LocalBasket";
import { Basket } from "./Services/Basket/Basket";
import { Center } from "./Components/Utils/Center";
import { Row } from "./Components/Utils/Row";
import { MiniBasketBox } from "./Components/MiniBasketBox";
import { PageHeader } from "./Components/PageTop";
import { PageFooter } from "./Pages/PageFooter";
import { BasketSummaryPage } from "./Components/OrderForm";

moment?.locale('pl')

NumberInput.DefaultCssClasses = "uk-input uk-form-width-small";
TextInput.DefaultCssClasses = "uk-input";
Button.DefaultCssClasses = "uk-button uk-button-default";
Select.DefaultCssClasses = "uk-select";
ModalWindow.DefaultCssClasses = "tf-modal-window";
ModalWindow.Hook = Root.Hook;
MultilineInput.DefaultCssClasses = "uk-textarea";


(async () =>
{
    // new ProductCategory("Rejestratory", "https://www.specteam.pl/img/product_categories/cat_rej.jpg", "Dyskretne nagrywanie z ukrycia"),
    // new ProductCategory("Detektory", "https://www.specteam.pl/img/product_categories/cat_det.jpg", "Skuteczne wykrywanie nadajników i kamer"),
    // new ProductCategory("Lokalizatory", "https://www.specteam.pl/img/product_categories/cat_lok.jpg", "Śledzenie pojazdów i nie tylko"),
    // new ProductCategory("Podsłuchy", "https://www.specteam.pl/img/product_categories/cat_pod.jpg", "Czuły nasłuch z odległości"),

    const root = new Root("body").Class("root")

    const _authApi = new AuthApi("https://authv2.specteam.pl");
    const _userStorage = new UserLocalStorage();
    const _auth = new AuthService(_authApi, _userStorage);
    _auth.TryAutologin();
    const _basketStorage = new BasketLocalStorage();
    const _storage = new OnlineStorage("https://storage.specteam.pl/v3", _auth.Info?.App?.WebsiteContentStorageAccessToken)
    const _converter = new WebsiteContentConverter();
    const _repo = new RawWebsiteContentRepo(_storage);
    const _websiteContentProvider = new WebsiteContentProvider(_repo, _converter);
    const _basket = new Basket(_basketStorage, _websiteContentProvider);

    const hash = new HashRef()
    const content = new DestroyingPatternContentSwitcher(hash).Class("page-main")
        .AddContent("product/:prod", ({ prod }) => new ProductPage(_websiteContentProvider, _basket, _auth, prod))
        .AddContent("edit/product/:product", ({ product }) => new ProductEditPage(_websiteContentProvider, _auth, product))
        .AddContent("add/product", () => new ProductEditPage(_websiteContentProvider, _auth))
        .AddContent("admin/login", () => new LoginPage(_auth))
        .AddContent("category/:categoryName", ({ categoryName }) => new CategoryPage(_websiteContentProvider, categoryName))
        .AddContent("edit/category", () => new CategoryEditPage(_websiteContentProvider, _auth))
        .AddContent("edit/page", () => new PageEditPage(_websiteContentProvider, _auth))
        .AddContent("kontakt", () => new ContactPage(_websiteContentProvider, _auth))
        .AddContent("o-nas", () => new AboutUsPage(_websiteContentProvider, _auth))
        .AddContent("blog", () => new BlogPage(_websiteContentProvider, _auth))
        .AddContent("blog/:url", ({ url }) => new BlogPage(_websiteContentProvider, _auth, url))
        .AddContent("basket", () => new BasketPage(_websiteContentProvider, _basket))
        // .AddContent("basket/summary", () => new BasketSummaryPage())
        .AddDefaultContent(() => new MainPage(_websiteContentProvider, _auth))
    // .AddDefaultContent(() => "🐾 Nic tu nie ma")

    root.Append(
        new PageHeader(_basket),
        content,
        new PageFooter(),
    )

})()
