import { Div, Spinner } from "@tblabs/truffle";
import { Center } from "./Utils/Center";


export class Loader extends Div
{
    constructor(text: string = "Ładowanie...")
    {
        super();

        this.Append(
            new Spinner().MarginRight(8), 
            text,
        )
    }
}

export class CenteredLoader extends Center
{
    constructor(text: string = "Ładowanie...")
    {
        super();
        this.Margin(32)

        this.Append(
            new Loader(text),
        )
    }
}
