import { Div, RefDate, RefNumber, DateInput, Label, Span, Button } from "@tblabs/truffle";
import moment from "moment";


export class DatesRange extends Div
{
    public DaysCount = new RefNumber()

    constructor(public Start: RefDate, public End: RefDate)
    {
        super()

        Start.value = moment(new Date()).hour(12).minute(0).toDate();
        End.value = moment(new Date()).hour(12).minute(0).toDate();
        Start.OnChange(v =>
        {
            this.DaysCount.value = moment(End.value).diff(v, 'days') + 1;
            if (this.DaysCount.value <= 0)
                End.value = Start.value;
        })
        End.OnChange(v =>
        {
            this.DaysCount.value = moment(v).diff(Start.value, 'days') + 1;
            if (this.DaysCount.value <= 0)
                Start.value = End.value;
        }, true)
    }

    public AddDay()
    {
        this.End.value = moment(this.End.value).add(1, 'day').toDate();
    }

    public SubtractDay()
    {
        this.End.value = moment(this.End.value).subtract(1, 'day').toDate();
    }
}

export class DatesRangeInput extends Div
{
    constructor(range: DatesRange)
    {
        super()

        this.Append(
            new Span("Od").Margin(8),
            new DateInput(range.Start).Width(140),
            new Span("do").Margin(8).MarginLeft(12),
            new DateInput(range.End).Width(140),
            " = ",
            new Label(range.DaysCount, v => `${v} ${(v == 1) ? "dzień" : "dni"}`).Color("#222").DisplayInlineBlock().Width(70)
                .When(range.DaysCount, v => +v <= 0, _ => _.Color("red"), _ => _.Color("#888")),
            new Button("➖", () => range.SubtractDay()).Padding(0),
            new Button("➕", () => range.AddDay()).Padding(0),
        )
    }
}