"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Validator = exports.ValidationResult = void 0;
var RV_1 = require("../Core/RV");
var Ref_1 = require("../Core/Ref");
var RefBool_1 = require("../Core/RefBool");
var ValidationResult = /** @class */ (function () {
    function ValidationResult(IsValid, IsNotValid, FirstError) {
        if (IsValid === void 0) { IsValid = new RefBool_1.RefBool(); }
        if (IsNotValid === void 0) { IsNotValid = new RefBool_1.RefBool(true); }
        if (FirstError === void 0) { FirstError = new Ref_1.Ref(""); }
        this.IsValid = IsValid;
        this.IsNotValid = IsNotValid;
        this.FirstError = FirstError;
    }
    return ValidationResult;
}());
exports.ValidationResult = ValidationResult;
var ValidationRule = /** @class */ (function () {
    function ValidationRule(predicate, message) {
        this.predicate = predicate;
        this.message = message;
    }
    return ValidationRule;
}());
var Validator = /** @class */ (function () {
    function Validator(valueToValidate, isEnabled) {
        if (isEnabled === void 0) { isEnabled = true; }
        var _this = this;
        this.valueToValidate = valueToValidate;
        this.isEnabled = isEnabled;
        this.isValid = new RefBool_1.RefBool();
        this.isNotValid = new RefBool_1.RefBool(true);
        this.firstErrorMessage = new Ref_1.Ref("");
        this.rules = [];
        this.wasInitiallyValidated = false;
        this.valueToValidate.OnChange(function (v) {
            if (_this.isEnabled) {
                _this.Validate();
            }
        });
    }
    Validator.prototype.Enable = function (enable) {
        var _this = this;
        if (enable === void 0) { enable = true; }
        RV_1.RV.Use(enable, function (v) {
            _this.isEnabled = v;
            if (_this.isEnabled)
                _this.Validate();
        });
        return this;
    };
    Validator.prototype.Enabled = function () {
        this.isEnabled = true;
        this.Validate();
        return this;
    };
    Validator.prototype.Disabled = function () {
        this.isEnabled = false;
        return this;
    };
    Validator.prototype.Validate = function () {
        for (var _i = 0, _a = this.rules; _i < _a.length; _i++) {
            var rule = _a[_i];
            var isValid = rule.predicate(this.valueToValidate.value);
            if (isValid == false) {
                this.firstErrorMessage.value = rule.message;
                this.isValid.value = false;
                this.isNotValid.value = true;
                return this;
            }
        }
        this.firstErrorMessage.value = "";
        this.isValid.value = true;
        this.isNotValid.value = false;
        return this;
    };
    Validator.prototype.MustBeNotEmpty = function (message) {
        if (message === void 0) { message = "Value can not be empty"; }
        this.AddRule(function (v) { return !!v; }, message);
        return this;
    };
    Validator.prototype.MustBeLowerThan = function (value, message) {
        if (message === void 0) { message = "Value must be lower than " + value; }
        this.AddRule(function (v) { return +v < value; }, message);
        return this;
    };
    Validator.prototype.MustBeLowerOrEqualThan = function (value, message) {
        if (message === void 0) { message = "Value must be lower or equal to " + value; }
        this.AddRule(function (v) { return +v <= value; }, message);
        return this;
    };
    Validator.prototype.MustBeGraterThan = function (value, message) {
        if (message === void 0) { message = "Value must be greater than " + value; }
        this.AddRule(function (v) { return +v > value; }, message);
        return this;
    };
    Validator.prototype.MustBeGraterOrEqualThan = function (value, message) {
        if (message === void 0) { message = "Value must be greater or equal to " + value; }
        this.AddRule(function (v) { return +v >= value; }, message);
        return this;
    };
    Validator.prototype.MustBeOneOf = function (options, message) {
        if (message === void 0) { message = "Must be ".concat(options.join(" or ")); }
        this.AddRule(function (v) { return options.includes(v); }, message);
        return this;
    };
    Validator.prototype.AddRule = function (predicate, messageIfNotValid) {
        this.rules.push(new ValidationRule(predicate, messageIfNotValid));
        return this;
    };
    Object.defineProperty(Validator.prototype, "IsValid", {
        get: function () {
            return this.Result.IsValid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Validator.prototype, "IsNotValid", {
        get: function () {
            return this.Result.IsNotValid;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Validator.prototype, "FirstError", {
        get: function () {
            return this.Result.FirstError;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Validator.prototype, "Result", {
        get: function () {
            if (this.wasInitiallyValidated === false && this.isEnabled) {
                this.Validate();
                this.wasInitiallyValidated = true;
            }
            return new ValidationResult(this.isValid, this.isNotValid, this.firstErrorMessage);
        },
        enumerable: false,
        configurable: true
    });
    return Validator;
}());
exports.Validator = Validator;
