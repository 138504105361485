"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CollectionView = void 0;
var Ref_1 = require("./Ref");
var RefBase_1 = require("./RefBase");
var Collection_1 = require("./Collection");
var CollectionView = /** @class */ (function (_super) {
    __extends(CollectionView, _super);
    function CollectionView(data) {
        var _this = _super.call(this) || this;
        _this.Items = [];
        _this.filtersRefs = [];
        _this.filters = [];
        _this.FilteredCount = new Ref_1.Ref(0);
        _this.collection = new Collection_1.Collection();
        if (Array.isArray(data)) {
            _this.collection = new Collection_1.Collection(data);
        }
        else if (data instanceof Collection_1.Collection) {
            _this.collection = data;
        }
        _this.collection.AddSubscriber(_this);
        return _this;
    }
    Object.defineProperty(CollectionView.prototype, "Collection", {
        get: function () {
            return this.collection;
        },
        enumerable: false,
        configurable: true
    });
    CollectionView.prototype.OnChange = function (handler, callThisCallbackRightAway) {
        this.onChangeHandler.AddHandler(handler);
        if (callThisCallbackRightAway == true) {
            handler(this.Items, true);
        }
        return this;
    };
    CollectionView.prototype.ForEach = function (fn) {
        this.onRefresh = fn;
        return this;
    };
    CollectionView.prototype.Refresh = function () {
        var _this = this;
        this.Items = this.collection.Items;
        this.filters.forEach(function (filterPredicate, index) {
            var refValue = _this.filtersRefs[index] instanceof Ref_1.Ref
                ? _this.filtersRefs[index].value
                : _this.filtersRefs[index].Items;
            _this.Items = _this.Items.filter(function (item) { return filterPredicate(item, refValue); });
        });
        this.FilteredCount.value = this.Items.length;
        if (this.start && this.count) {
            this.Items = this.Items.slice(this.start.value, this.start.value + this.count.value);
        }
        if (this.onRefresh)
            this.Items.forEach(function (record) { return _this.onRefresh(record); });
        this.onChangeHandler.CallHandlers(this.Items);
        this.RefreshSubscribers();
        return this;
    };
    CollectionView.prototype.Filter = function (ref, predicate) {
        ref.AddSubscriber(this);
        this.filtersRefs.push(ref);
        this.filters.push(predicate);
        return this;
    };
    CollectionView.prototype.See = function (start, count) {
        start.AddSubscriber(this);
        count.AddSubscriber(this);
        this.start = start;
        this.count = count;
        return this;
    };
    return CollectionView;
}(RefBase_1.RefBase));
exports.CollectionView = CollectionView;
