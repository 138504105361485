import { Collection, MultilineInput } from "@tblabs/truffle";
import { DescriptionField } from "../Models/DescriptionField";
import { TextDescriptionField } from "./TextDescriptionField";
import { FieldEditor } from "./FieldEditor";


export class TextDescriptionFieldEntryView extends FieldEditor
{
    constructor(description: TextDescriptionField, descriptions: Collection<DescriptionField>)
    {
        super("Text", description, descriptions, [
            new MultilineInput(description.Text).Rows(8).TextAlignLeft().Padding(12).Placeholder("Czysty tekst"),
        ]);
    }
}
