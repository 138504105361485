import { AdminEditButton } from "../Components/AdminEditButton";
import { DescriptionFieldBox } from "../Components/DescriptionFieldBox";
import { Loader } from "../Components/Loader";
import { PageLogo } from "../Components/PageTop";
import { Row } from "../Components/Utils/Row";
import { AuthService } from "../Services/Auth/AuthService";
import { WebsiteContentProvider } from "../Services/Content/WebsiteContentProvider";
import { Page } from "./Page";


export class AboutUsPage extends Page
{
    private loader = new Loader();
    private content = new Row();

    constructor(private _content: WebsiteContentProvider, private _user: AuthService)
    {
        super();

        this.body.Append(
            new PageLogo(),
            this.loader,
            this.content
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._content.Get();

        this.loader.RemoveSelf();

        const about = content.AboutUs;

        if (!about)
        {
            this.Append("Nie znaleziono wpisu");
            return;
        }

        this.content.Insert(
            this._user.IsAuthorized && new AdminEditButton(`edit/page`),
            ...content.AboutUs.Items.map(entry => new DescriptionFieldBox(entry)),
        )
    }
}
