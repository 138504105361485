import { Product, RawProduct } from "../../Models/Product";
import { RawWebsiteContent, WebsiteContent } from "../../Models/WebsiteContent";
import { RawWebsiteContentRepo } from "../Repo/RawWebsiteContentRepo";
import { StorageResponse } from "../Storage/Models/StorageResponse";
import { OnlineStorage } from "../Storage/OnlineStorage";
import { WebsiteContentConverter } from "./WebsiteContentConverter";

export class ContentUpdateStatus
{
    public IsSuccess = false;
    public ContentSize = -1;
    public ErrorMessage = "";
}
function Delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
}

// TODO: local storage
// TODO: copy saver
export class WebsiteContentProvider
{
    private cache: RawWebsiteContent;

    constructor(
        private _repo: RawWebsiteContentRepo,
        private _converter: WebsiteContentConverter)
    { }

    public async Update(content: WebsiteContent): Promise<ContentUpdateStatus>
    {
        const raw = this._converter.ToRaw(content);

        const result = await this._repo.Update(raw);

        if (result.IsSuccess)
        {
            const rawContent = await this._repo.Get();

            if (rawContent)
                this.cache = rawContent;
            else throw new Error(`Could not get content`)
        }

        const updateStatus = new ContentUpdateStatus();
        updateStatus.IsSuccess = result.IsSuccess;
        updateStatus.ContentSize = JSON.stringify(raw).length / 1024;
        updateStatus.ErrorMessage = result.ErrorMessage

        return updateStatus;
    }

    private hardcoded: RawWebsiteContent = {
        ProductCategories: [
            {
                Id: "K",
                Title: "Mikrokamery",
                Subtitle: "Łączność na egzaminy i nie tylko",
                Url: "mikrokamery",
                Image: "https://www.specteam.pl/img/product_categories/cat_cam.jpg",
                Description: "Nasze zestawy pozwalają na dyskretną łączność na nieograniczoną odległość w doskonałej jakości. Najprawdopodobniej są to najlepsze zestawy tego typu produkowane tylko przez nas",
            },
            {
                Id: "M",
                Title: "Mikrosłuchawki",
                Subtitle: "Niezauważalny kontakt przez telefon",
                Url: "mikrosluchawki",
                Image: "https://www.specteam.pl/img/product_categories/cat_ms.jpg",
                Description: "Subminiaturowe zestawy słuchawkowe sprawdzą się wszędzie tam gdzie niezbędna jest dyskretna łączność głosowa.......",
            },
            {
                Id: "D",
                Title: "Detektory",
                Subtitle: "Wykrywanie podsłuchów",
                Url: "detektory",
                Image: "https://www.specteam.pl/img/product_categories/cat_det.jpg",
                Description: "ddddddddddddddd",
            },
        ],
        Products: [
            {
                Id: "TX",
                CategoryId: "K",
                Title: "Zestaw klasyczny",
                Subtitle: "Nasz najlepszy zestaw z kamerą",
                Url: "zestaw-klasyczny",
                BasketName: "Mikrokamera z mikrosłuchawką",
                Tags: ["kamera", "mikrokamera", "mikro", "minikamera", "mini", "egzamin", "matura", "sesja", "zestaw", "egzaminacyjny"],
                RentPrices: [
                    { Value: 599, Days: 3 },
                    { Value: 799, Days: 5 },
                    { Value: 799, Days: 5 },
                    { Value: 1499, Days: 14 },
                ],
                SalePrice: 7200,
                Features: `Nielimitowany zasięg
Duża rozdzielczość, idealnie widoczny tekst
Działa nawet przy słabym zasięg
Obiektyw kamery w guzik
Działa z każdym telefonem i nie tylko
Bardzo prosta obsługa`,
                Description: [
                    {
                        Type: "Markdown", Header: "Sample title #1",
                        Markdown: "Sample *markdown* **text**"
                    },
                    {
                        Type: "Faq", Header: "Q&A",
                        Questions: [
                            {
                                Question: "Co muszę mieć swojego aby korzystać z zestawu?",
                                Answer: "Generalnie nic. Potrzebny jest tyko jakikolwiek telefon z dostępem do internetu"
                            },
                            {
                                Question: "Co muszę mieć przy sobie?",
                                Answer: "Kamerę, słuchawkę, pętle bluetooth oraz telefon"
                            },
                            {
                                Question: "Co musi mieć odbiorca?",
                                Answer: "Komputer z dostępem do internetu i telefon"
                            },
                            {
                                Question: "Co musi mieć odbiorca?",
                                Answer: "Komputer z dostępem do internetu i telefon"
                            },
                            {
                                Question: "Co musi mieć odbiorca?",
                                Answer: "Komputer z dostępem do internetu i telefon"
                            },
                        ]
                    },
                ],
                //                 {
                // "🕹️ Zasada działania": `**Miniaturowa kamera** w guziku *automatycznie*, cały czas wysyła *doskonałej jakości zdjęcia*, średnio co 2-3 sekundy. Przypomina to trochę film poklatkowy, z którego można wybrać i *zapisać* najlepsze ujęcia.  
                // Podglądu można dokonać na *naszej dedykowanej stronie www* po wpisaniu *unikalnego* dla każdego użytkownika hasła.  
                // Podgląd jest możliwy w dowolnym miejscu na świecie. *Nie ma limitu odległości*.  
                // Tekst na zdjęciach jest doskonale czytelny. Kamera pracuje w rozdzielczości większej niż *Full HD*.

                // **Mikrosłuchawka** jest *bezprzewodowa* i kompletnie *niewidoczna*. Posiada *czuły mikrofon*, więc możliwa jest komunikacja w dwie strony.  
                // `,
                //                     "🎮 Demo": `Chcesz zobaczyć jak to działa na żywo? [Wejdź tutaj](http://radio.specteam.pl)!`,
                //                     "🧵 Specyfikacja techniczna": [
                //                         ["Zasięg nadajnika", "Bez ograniczeń\n(działa przez internet)"],
                //                         ["Rozdzielczość kamery", "Regulowana w czasie pracy:\n1200x900px, 1600x1200px, 2000x1600px lub inna dowolna\nMaksymalna: 2500x1900px"],
                //                         ["Czas pracy", "od 4 do 6 godzin, moża dowolnie zwiększyć"],
                //                         ["Rozmiary kamery", "11mm x 9mm x 7mm, średnica guzika: 11 mm"],
                //                         ["Rozmiary nadajnika", "75mm x 30mm x 5mm"],
                //                         ["Czas odświeżania", "około 2 sekund dla rozdzielczości 1600 x 1200 px"],
                //                         ["Ogniskowa kamery", "Ustawiona na czytanie tekstu z odległości 15-40 cm\n(można ustawić dowolnie większą)"],
                //                         ["Pole widzenia kamery", "z 22cm: 18x24cm, z 40cm: 30x42cm, z 50cm: 38x51cm"],
                //                         ["Czas ładowania", "ok 4 - 6h"],
                //                         ["Przewód zasilania", "miniUSB 28 cm, lub dowolnie dłuższy"],
                //                         ["Odbiornik", "Komputer lub telefon z dowolną przeglądarką internetową\ni dostępem do internetu"],
                //                         ["Wykorzystanie danych", "ok 250MB/h"],
                //                     ],
                //                     "📦 Zawartość zestawu": `
                // - Mikrokamera (1 szt.)
                // - Mikrosłuchawka aktywna/bateryjna (1 szt.)
                // - Baterie do mikrosłuchawki (co najmniej 2 szt.)
                // - Słuchawka Nano (4 szt.)
                // - Pętla indukcyjna Bluetooth Samsung lub Jabra (1 szt.)
                // - Pasywna pętla indukcyjna (1 szt.) [OPCJA]
                // - Ładowarka sieciowa USB (1 szt.)
                // - Przewód zasilania 25cm +/-5cm (1 szt.)
                // - Powerbank 4h+ (1 szt.)
                // - Guziki podobne do obiektywu (co najmniej 10 szt.)
                // - Etui (1 szt.)
                // `,
                //                     "🏭 Producent": `Ten zestaw jest jest *naszym autorskim dziełem*. Cała *konstrukcja* jak i *oprogramowanie* zostało stworzone przez nas. Korzystamy z najlepszych dostępnych hostingów oraz regularnie aktualizujemy oprogramowanie by było zawsze kompatybilne z najnowszymi trendami. To właśnie dzięki temu te zestawy działają tak dobrze w porównaniu z nieobliczalnymi kamerkami streamingowymi czy kiepsko wykonanymi pętlami indukcyjnymi. Więcej na ten temat można znaleźć w artykule 🐾 [Dlaczego nasza kamera jest lepsza niż streaming](https://blog.specteam.pl/#Dlaczego-nasza-kamera-jest-lepsza-niz-streaming)`,
                // "❓ FAQ": `
                // - *Co muszę mieć swojego aby korzystać z zestawu?*   
                // Generalnie nic. Potrzebny jest tyko jakikolwiek telefon z dostępem do internetu.
                // - *Co muszę mieć przy sobie?*  
                // Kamerę, słuchawkę, pętle bluetooth oraz telefon.
                // - *Co musi mieć odbiorca?*  
                // Komputer z dostępem do internetu i telefon.`
                // },
                Images: [
                    { Url: "https://www.specteam.pl/img/product_categories/cat_cam.jpg", Description: "Widok całego zestawu" },
                    { Url: "https://www.specteam.pl/img/product_categories/cat_ms.jpg", Description: "Widok całego zestawu" },
                    { Url: "https://www.specteam.pl/img/product_categories/cat_lok.jpg", Description: "Widok całego zestawu" },
                    { Url: "https://www.specteam.pl/img/product_categories/cat_pod.jpg", Description: "Widok całego zestawu" },
                ]
            },
        ],
        AboutUs: `Musimy to przyznać - działamy dość nietypowo. I to nie dlatego, że wypożyczamy i budujemy dość drogie i zaawansowane urządzenia szpiegowskie, ale dlatego że odnajdujemy w tym pasję i radość.  
Oprócz tego nasz sposób działania na rynku jest również niestandardowy. Zamiast biura wykorzysujemy agentów terenowych. Nie wymagamy umów najmu ani nie bierzemy pod zastaw wysokich kaucji (i nie wynika to z naiwnego zaufania do naszych klientów 
/
ale nie dlatego że ufamy klientom - większość urządzeń automatycznie się dezaktywuje po określonym czasie).
Wciąż rozwijamy nasze produkty i cieszymy się każdym podsuniętym przez Was pomysłem......  

Chcesz wiedzieć więcej?  
❔Dowiedz się dlaczego nie znajdziesz w internecie opinii na nasz temat?  
albo  
❔Dlaczego na tej stronie nie znajdziesz tak wielu produktów jak w podobnych sklepach?  
lub  
❔Jak rozpocząć z nami współprace?`
    };

    public async Get(): Promise<WebsiteContent>
    {
        // await Delay(500)
        const raw = this.cache || await this._repo.Get()
            // || this.hardcoded;
            // console.log(raw)
            this.cache = raw;
            const content = this._converter.FromRaw(raw);
            
            // console.log(content)
        if (!content)
            throw new Error(`Could not get website content`);

        return content;
    }

    // private productsCache: RawProduct[] = [];
    // public async GetProduct(id: string): Promise<Product>
    // {
    //     const raw = this.productsCache || await this._repo.GetProduct(id)
    //         || this.hardcoded;
    //     // console.log(content)
    //     this.productsCache = raw;

    //     const content = this._converter.FromRaw(raw);

    //     if (!content)
    //         throw new Error(`Could not get website content`);

    //     return content;
    // }
}
