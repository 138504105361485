

export class RentPriceCalculator
{
    public static Calc(prices: { price: number; days: number; }[], totalDays: number): number
    {
        if (totalDays > 90)
        {
            throw new Error(`Max range is 90 days`);
        }

        let range = 0;

        if (totalDays <= prices[0].days)
        {
            return prices[0].price;
        }

        prices.unshift({ price: prices[0].price, days: 0 }); // hack to simplify logic


        // prices.push({ price: prices[prices.length - 1].price * 2, days: 1000000 }) // hack to simplify logic
        for (let i = 0; i < prices.length - 1; i++)
        {
            // console.log("#", i, ":", prices[i].days, "-", prices[i + 1].days, ", price for that range:", prices[i].price, ", searching for", totalDays)
            if ((prices[i].days <= totalDays) && (totalDays <= prices[i + 1].days))
            {
                range = i;

                const rangeSize = prices[range + 1].days - prices[range].days;
                const pricePerDayInRange = (prices[range + 1].price - prices[range].price) / rangeSize;
                const dayInRange = totalDays - prices[range].days;
                // console.log('range size:', rangeSize, ', per day:', pricePerDayInRange, ', day in range:', dayInRange)
                return Math.round(prices[range].price + dayInRange * pricePerDayInRange);
                break;
            }
        }

        if (range >= prices.length - 1)
        {
            console.log('found range index:', range);
            range = prices.length;
        }

        return prices[range].price;
    }
}
