"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Radio = exports.RadioConfig = exports.RadioOption = exports.RadioInput = void 0;
var Ref_1 = require("../../Core/Ref");
var Div_1 = require("../Containers/Div");
var ComponentBase_1 = require("../../Core/ComponentBase");
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var Span_1 = require("../Containers/Span");
var RadioInput = /** @class */ (function (_super) {
    __extends(RadioInput, _super);
    function RadioInput(ref, value, uniqueId) {
        var _this = _super.call(this, "input", ref) || this;
        _this.ref = ref;
        _this.value = value;
        _this.uniqueId = uniqueId;
        _this.Attribute("type", "radio");
        _this.Attribute("name", uniqueId);
        _this.Attribute("value", value.toString());
        _this.Refresh();
        return _this;
    }
    RadioInput.prototype.Refresh = function () {
        this.element.checked = this.ref.value == this.value;
    };
    return RadioInput;
}(RefComponentBase_1.RefComponentBase));
exports.RadioInput = RadioInput;
var RadioOption = /** @class */ (function (_super) {
    __extends(RadioOption, _super);
    function RadioOption(ref, value, label, uniqueId, config) {
        var _a;
        var _this = _super.call(this, "label") || this;
        _this.ref = ref;
        _this.value = value;
        _this.label = label;
        _this.uniqueId = uniqueId;
        _this.config = config;
        _this.Radio = new RadioInput(_this.ref, _this.value, _this.uniqueId).Style(_this.config.RadioStyle);
        _this.Label = new Span_1.Span().Style(_this.config.LabelStyleWhenEnabled);
        _this.isEnabled = true;
        if (typeof label == "string")
            _this.Label.Text(label);
        else
            (_a = _this.Label).Append.apply(_a, label);
        _this.Append(_this.Radio, _this.Label);
        _this.OnClick(function () {
            if (_this.isEnabled)
                _this.ref.value = value;
        });
        return _this;
    }
    RadioOption.prototype.Enable = function (isEnabled) {
        var _this = this;
        this.React(isEnabled, function (v) { return v ? _this.Enabled() : _this.Disabled(); });
        return this;
    };
    RadioOption.prototype.Disable = function (isDisabled) {
        var _this = this;
        this.React(isDisabled, function (v) { return v ? _this.Disabled() : _this.Enabled(); });
        return this;
    };
    RadioOption.prototype.EnableWhen = function (watch, predicate) {
        var _this = this;
        if (predicate === void 0) { predicate = function (value) { return !!value; }; }
        this.React(watch, function (v) { return _this.Enable(predicate(v)); });
        return this;
    };
    RadioOption.prototype.DisableWhen = function (watch, predicate) {
        var _this = this;
        if (predicate === void 0) { predicate = function (value) { return !!value; }; }
        this.React(watch, function (v) { return _this.Disable(predicate(v)); });
        return this;
    };
    RadioOption.prototype.Disabled = function () {
        this.isEnabled = false;
        this.Radio.Disabled();
        this.Label.CursorDefault().Style(this.config.LabelStyleWhenDisabled);
        return this;
    };
    RadioOption.prototype.Enabled = function () {
        this.isEnabled = true;
        this.Radio.Enabled();
        this.Label.CursorPointer().Style(this.config.LabelStyleWhenEnabled);
        return this;
    };
    return RadioOption;
}(ComponentBase_1.ComponentBase));
exports.RadioOption = RadioOption;
var RadioConfig = /** @class */ (function () {
    function RadioConfig() {
        this.RadioStyle = "";
        this.LabelStyleWhenEnabled = "color: #666";
        this.LabelStyleWhenDisabled = "color: #ccc";
    }
    return RadioConfig;
}());
exports.RadioConfig = RadioConfig;
var Radio = /** @class */ (function (_super) {
    __extends(Radio, _super);
    function Radio(ref, config) {
        if (ref === void 0) { ref = new Ref_1.Ref(0); }
        var _this = _super.call(this) || this;
        _this.ref = ref;
        _this.Config = new RadioConfig();
        _this.uniqueName = "radio-id-" + Math.random().toString().substring(2);
        if (config) {
            var keys = Object.keys(config);
            keys.forEach(function (k) {
                _this.Add(config[k], k);
            });
        }
        return _this;
    }
    Radio.prototype.Add = function (value, label, thisOption) {
        var item = new RadioOption(this.ref, value, label, this.uniqueName, this.Config);
        thisOption === null || thisOption === void 0 ? void 0 : thisOption(item);
        this.Append(item);
        return this;
    };
    // public SetConfig(config: Partial<RadioConfig>): this
    Radio.prototype.Configure = function (callback) {
        // if (config.LabelStyleWhenEnabled)
        //     this.Config.LabelStyleWhenEnabled = config.LabelStyleWhenEnabled;
        // if (config.LabelStyleWhenDisabled)
        //     this.Config.LabelStyleWhenDisabled = config.LabelStyleWhenDisabled;
        callback(this.Config);
        return this;
    };
    Radio.Name = "Radio";
    return Radio;
}(Div_1.Div));
exports.Radio = Radio;
