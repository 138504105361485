import { RawWebsiteContent, WebsiteContent } from "../../Models/WebsiteContent";
import { StorageResponse } from "../Storage/Models/StorageResponse";
import { OnlineStorage } from "../Storage/OnlineStorage";
import { ReadQuery } from "./ReadQuery";
import { UpdateCommand } from "./UpdateCommand";


export class RawWebsiteContentRepo
{
    private FILE = "latest";
    private FOLDER = "Prod";

    constructor(
        private _storage: OnlineStorage)
    { }

    public async Get(): Promise<RawWebsiteContent | null>
    {
        const response = await this._storage.SendMessage(new ReadQuery(this.FILE, this.FOLDER));

        if (!response.IsSuccess)
        {
            return null;
        }
        
        const raw = response.Result;

        return raw;
    }

    public async Update(raw: RawWebsiteContent): Promise<StorageResponse>
    {
        const result = await this._storage.SendMessage(new UpdateCommand(this.FILE, this.FOLDER, raw, true));
    
        return result;
    }
}
