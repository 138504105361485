"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.NonRefTextInput = exports.TextInput = void 0;
var ComponentBase_1 = require("../../Core/ComponentBase");
var Ref_1 = require("../../Core/Ref");
var RefComponentBase_1 = require("../../Core/RefComponentBase");
var HandlersCollection_1 = require("../../Core/Tools/HandlersCollection");
var TextInput = /** @class */ (function (_super) {
    __extends(TextInput, _super);
    function TextInput(ref) {
        if (ref === void 0) { ref = new Ref_1.Ref(""); }
        var _this = _super.call(this, "input", ref) || this;
        _this.onChange = new HandlersCollection_1.HandlersCollection();
        _this.Class(TextInput.DefaultCssClasses);
        _this.element.addEventListener('input', function (e) {
            _this.ref.value = e.target.value;
            _this.onChange.Call(_this.ref.value, e);
        });
        _this.Refresh();
        return _this;
    }
    TextInput.prototype.Placeholder = function (placeholderText) {
        var _this = this;
        if (placeholderText)
            this.React(placeholderText, function (v) { return _this.element.setAttribute("placeholder", v); });
        return this;
    };
    TextInput.prototype.OnChange = function (handler) {
        this.onChange.Add(handler);
        return this;
    };
    TextInput.prototype.Refresh = function () {
        this.element.value = this.ref.value;
        if (this.showThatChanged)
            this.ref.HasChanged
                ? this.element.classList.add("tf-value-changed")
                : this.element.classList.remove("tf-value-changed");
    };
    TextInput.Name = "TextInput";
    TextInput.DefaultCssClasses = "";
    return TextInput;
}(RefComponentBase_1.RefComponentBase));
exports.TextInput = TextInput;
var NonRefTextInput = /** @class */ (function (_super) {
    __extends(NonRefTextInput, _super);
    function NonRefTextInput(value, onChange) {
        var _this = _super.call(this, "input") || this;
        _this.value = value;
        _this.Class(TextInput.DefaultCssClasses);
        _this.element.addEventListener('input', function (e) {
            // this.ref.SetValue(e.target.value, this);
            onChange(e.target.value);
        });
        _this.Refresh();
        return _this;
    }
    NonRefTextInput.prototype.Placeholder = function (placeholder) {
        this.element.setAttribute("placeholder", placeholder);
        return this;
    };
    NonRefTextInput.prototype.Refresh = function () {
        this.element.value = this.value;
        // if (this.showThatChanged)
        //     this.ref.HasChanged
        //         ? this.element.classList.add("tf-value-changed")
        //         : this.element.classList.remove("tf-value-changed");
    };
    NonRefTextInput.DefaultCssClasses = "";
    return NonRefTextInput;
}(ComponentBase_1.ComponentBase));
exports.NonRefTextInput = NonRefTextInput;
