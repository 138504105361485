import { Collection, CollectionPrinter, Div, Link } from "@tblabs/truffle";
import { DescriptionField } from "../../Models/DescriptionField";
import { TableItem } from "../../Models/TableItem";
import { FieldEditor } from "../FieldEditor";
import { TableDescriptionField } from "./TableDescriptionField";
import { TableEditEntryView } from "./TableEditEntryView";


export class TableDescriptionFieldEntryView extends FieldEditor
{
    constructor(field: TableDescriptionField, fields: Collection<DescriptionField>)
    {
        super("Tabela", field, fields, [
            new CollectionPrinter(field.Rows, x => new TableEditEntryView(x, field.Rows)),
            new Div().ClearBoth(),
            new Link("➕ Wiersz").OnClick(() => field.Rows.Add(new TableItem({ Left: "", Right: "" }))),
        ]);
    }
}
