"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Image = void 0;
var ComponentBase_1 = require("../../Core/ComponentBase");
var Image = /** @class */ (function (_super) {
    __extends(Image, _super);
    function Image(src, alt) {
        var _this = _super.call(this, "img") || this;
        _this.React(src, function (v) { return _this.Src(v); });
        _this.React(alt, function (v) { return _this.Alt(v); });
        return _this;
    }
    Image.prototype.Src = function (src) {
        this.Attribute("src", src);
        return this;
    };
    Image.prototype.AsBase64 = function (base64) {
        this.Attribute("src", "data:image/png;base64," + base64);
        return this;
    };
    Image.prototype.Alt = function (description) {
        if (description)
            this.Attribute("alt", description);
        return this;
    };
    Image.prototype.ObjectPosition = function (xAxis, yAxis) {
        var _this = this;
        this.React(xAxis, function (x) { return _this.Style("object-position", "".concat(x, "% ").concat(yAxis.value, "%")); });
        this.React(yAxis, function (y) { return _this.Style("object-position", "".concat(xAxis.value, "% ").concat(y, "%")); });
        return this;
    };
    Image.prototype.ObjectFit = function (fit) {
        var _this = this;
        this.React(fit, function (x) { return _this.Style("object-fit", x); });
        return this;
    };
    Image.Name = "Image";
    return Image;
}(ComponentBase_1.ComponentBase));
exports.Image = Image;
