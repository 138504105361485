import { RefString, RefNumber, TableBuilder, Div, Image, Span, Link, Select, TextInput, Line, Button, Ref, RefDate, Label, Radio, DestroyingContentSwitcher, RefBool } from "@tblabs/truffle";
import { Center } from "./Utils/Center";
import moment from "moment";
import { DatesRange, DatesRangeInput } from "./DatesRange";
import { DeliveryForm, DeliveryFormLabelToValue, DeliveryFormValueToLabel } from "../Models/DeliveryForm";
import { ReturnForm } from "../Models/ReturnForm";
import { InputRow } from "./InputRow";
import { Section } from "./Section";
import { Basket } from "../Services/Basket/Basket";
import { WebsiteContent } from "../Models/WebsiteContent";
import { PlusMinusInput } from "./PlusMinusInput";
import { HashLink } from "./Utils/HashLink";
import { DynamicBasket } from "./DynamicBasket";
import { Page } from "../Pages/Page";
import { Markdown } from "./Utils/Markdown";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { RefWatcher } from "./RefWatcher";
import { PaymentForm } from "./PaymentForm";
import { Cost } from "./Cost";
import { ReturnableCost } from "./ReturnableCost";
import { Discount } from "./Discount";
import { CenteredLoader, Loader } from "./Loader";
import { v4 as uuidv4 } from 'uuid';

export class BasketSummary
{
    public OrderId: string = "";
    public Customer = new Customer();
    public PaymentForm: PaymentForm = PaymentForm.Unset;
    public Delivery: DeliveryForm = DeliveryForm.Unset;
    public Return: ReturnForm = ReturnForm.Unset;
    public Costs: (Cost | ReturnableCost)[] = [];
    public Discounts: Discount[] = [];

    public Clear()
    {
        this.Costs = [];
        this.Discounts = [];
    }
    public get Sum(): number
    {
        return this.Costs.reduce((p, c) => p + c.Value, 0);
    }
    public get ToReturn(): number
    {
        return this.Costs.filter(x => x instanceof ReturnableCost).reduce((p, c) => p + c.Value, 0);
    }
    public Add(x: Cost | ReturnableCost | Discount): void
    {
        if (x instanceof Cost || x instanceof ReturnableCost)
            this.Costs.push(x)
        if (x instanceof Discount)
            this.Discounts.push(x)
    }

    public get NonReturnableCosts(): Cost[]
    {
        return this.Costs.filter(x => x instanceof Cost);
    }
    public get NonReturnableCostsSum(): number
    {
        return this.NonReturnableCosts.reduce((p, c) => p + c.Value, 0);
    }

    public Save()
    {
        const load = { Costs: this.Costs, Discounts: this.Discounts, Customer: {} };
        window.localStorage.setItem("basket-summary", JSON.stringify(load))
    }
}

export class RawCustomer
{
    public Name: string;
    public Address: string;
    public Email: string;
    public Phone: string;
    public Experience: number;
}
export class Customer
{
    public Name: string = "";
    public Address: string = "";
    public Email: string = "";
    public Phone: string = "";
    public Experience: number = (-1);
}
export class OrdersClientPackage
{
    public Customer: RawCustomer;
    public Delivery
}
export function Delay(ms: number)
{
    return new Promise(resolve => setTimeout(resolve, ms));
}

export class OrdersClient
{
    public async Send(summary: BasketSummary)
    {
        // http://localhost:4339/#create/
        await Delay(5000)
    }
}

export class OrderForm extends Div
{
    constructor(content: WebsiteContent, private basket: Basket, private _orders: OrdersClient)
    {
        super();

        const customerName = new RefString().Storable("customer-name")
        const customerPhone = new RefString().Storable("customer-phone")
        const customerEmail = new RefString().Storable("customer-email")
        const customerAddress = new RefString().Storable("customer-address")
        const customerExp = new RefNumber().Storable("customer-exp")
        const deliveryForm = new Ref<DeliveryForm>(DeliveryForm.Unset).Storable("delivery-form")
        const returnForm = new Ref<ReturnForm>(ReturnForm.Unset).Storable("return-form")
        const paymentForm = new Ref<PaymentForm>(PaymentForm.Unset).Storable("payment-form")
        const start = new RefDate(moment(new Date()).hour(12).minute(0).toDate()).Storable("rent-from")
        const end = new RefDate(moment(new Date()).hour(12).minute(0).toDate()).Storable("rent-to")
        const rentDatesRange = new DatesRange(start, end)
        const dynamicBasket = new DynamicBasket(content, basket, rentDatesRange.DaysCount)
        const summary = new BasketSummary()
        const view = new RefString("form")
        const depositInfo = new RefString()
        const depositReturnInfo = new RefString()
        const trainingInfo = new RefString()
        const allowCashPayment = new RefBool()
        const showCustomerDepositAccountInput = new RefBool()
        const place = new RefString("Warszawa/Wola");

        // 🧠
        RefWatcher.Watch([deliveryForm, paymentForm, returnForm, dynamicBasket.Items], () =>
        {
            const personalDelivery = [DeliveryForm.PersonalWithTraining, DeliveryForm.PersonalWithoutTraining].includes(deliveryForm.value);
            const personalReturn = [ReturnForm.Personal].includes(returnForm.value);

            // 🎓 Training
            trainingInfo.value = {
                [DeliveryForm.Pocztex]: `Szkolenie odbędzie się przez telefon. Otrzymasz także materiały do samodzielnej nauki`,
                [DeliveryForm.PersonalWithTraining]: `Szkolenie odbędzie się na miejscu. Otrzymasz także materiały do samodzielnej nauki`,
                [DeliveryForm.PersonalWithoutTraining]: `Szkolenie nie odbędzie się na miejscu. Zamiast tego otrzymasz materiały do samodzielnej nauki oraz dostęp do darmowej infolinii`,
            }[deliveryForm.value] || "⚠️ Błąd formularza!";

            // Package + Cash is not possible, so switch to the next available option
            if (deliveryForm.Is(DeliveryForm.Pocztex) && paymentForm.Is(PaymentForm.Cash))
                paymentForm.value = PaymentForm.DepositPrepaidServicePostpaid;
            // Nothing to rent + Personal delivery + Cash at delivery is not possible, so switch to the next available option
            if (!basket.IsAnythingToRent && !deliveryForm.Is(DeliveryForm.Pocztex) && paymentForm.Is(PaymentForm.CashAtDelivery))
                paymentForm.value = PaymentForm.Cash;

            // 💳 Payment
            allowCashPayment.value = personalDelivery;

            // 💰 Deposit
            depositInfo.value = {
                [PaymentForm.Cash]: `Kaucja zostanie pobrana gotówką podczas odbioru sprzętu`,
                [PaymentForm.DepositPrepaidServicePostpaid]: `Łączna suma kaucji wynosi ${dynamicBasket.GetDepositsSum()}zł. Płatne z góry przelewem`,
                [PaymentForm.PartialDepositPrepaidServicePostpaid]: `Łączna suma kaucji wynosi ${dynamicBasket.GetDepositsSum()}zł, z czego 60zł jest płatne z góry przelewem, pozostała kwota kaucji zostanie doliczona do kwoty pobrania`,
                [PaymentForm.FromPreviousOrder]: `Wykorzystamy kaucję z Twojego poprzedniego zamówienia o ile to możliwe (wartość kaucji jest taka sama i poprzednie zamówienie nie zostało jeszcze rozliczone)`,
                [PaymentForm.Crypto]: `Kaucja zostanie wliczona w koszt przelewu`,
                [PaymentForm.Transfer]: `Kwota kaucji zostanie doliczona do kwoty przelewu`
            }[paymentForm.value] || "⚠️ Błąd formularza!";

            // 💰 Deposit return
            showCustomerDepositAccountInput.value = personalDelivery && !personalReturn && paymentForm.Is(PaymentForm.Cash);

            depositReturnInfo.value = {
                [PaymentForm.Cash]: `Kaucja zostanie zwrócona na miejscu w gotówce`,
                [PaymentForm.Transfer]: `Kaucja zostanie zwrócona na konto, z którego przyszła`,
                [PaymentForm.DepositPrepaidServicePostpaid]: `Kaucja zostanie odesłana na konto, z którego przyszła`,
                [PaymentForm.PartialDepositPrepaidServicePostpaid]: `Kaucja zostanie zwrócona w dwóch przelewach`,
                [PaymentForm.Crypto]: `Kaucja zostanie zwrócona na wskazany portfel`,
                [PaymentForm.FromPreviousOrder]: `Jako że kaucja została opłacona w poprzednim zamówieniu - zostanie zwrócona w sposób zgodny z formą jej opłacenia`,
            }[paymentForm.value] || "⚠️ Błąd formularza!";
        });

        // 📺
        this.Append(
            new DestroyingContentSwitcher(view)
                .AddContent("form", () => new Div().Append(
                    new Section("🗓️ Okres wypożyczenia", [
                        new DatesRangeInput(rentDatesRange)
                    ]).Visible(this.basket.IsAnythingToRentRef, "block"),
                    new Section("🛒 Twoje zamówienie", [
                        new TableBuilder(dynamicBasket.Items)
                            .AddColumn("Usługa", p => [
                                new Label(p.Label),
                            ])
                            .AddColumn("Produkt", p => [
                                new Image(p.Picture).Height(44).Width(66).Margin(8).FloatLeft(),
                                new Div().TextAlignLeft().FloatLeft().MarginTop(8).Append(
                                    new Div().Append(
                                        new Link(p.Name, p.Link),
                                    ),
                                    new Span(p.Price).Color("#999").FontSize(12)
                                ),
                            ])
                            .AddColumn("Ilość", p => [
                                new PlusMinusInput(p.Quantity).MarginBottom(0),
                                new Label(p.DiscountLabel).FontSize(14).MarginTop(0)
                            ])
                            .AddColumn("Koszt", p => new Label(p.Cost, v => v + " zł").Bold().DisplayInlineBlock().Width(70))
                            .AddColumn("", (x, row) => 
                            {
                                row.TextAlignLeft()
                                return [
                                    new Link("❌ Usuń").NoDecorationOnHover().OnClick(() => dynamicBasket.Remove(x)),
                                ]
                            })
                            .ForEachRow(row => row.Background("#fafafa").MarginLeftRight(12))
                            .WhenEmpty(new Center("Brak produktów").MarginTopBottom(32))
                            .Build().WidthAuto().MarginAuto(),
                        new Center(
                            new HashLink("➕ Dodaj produkty").Color("#1e87f0")
                        ).MarginTop(20),
                    ]),
                    new Section("🚚 Odbiór / dostawa", [
                        new InputRow("Forma",
                            new Radio(deliveryForm).Class("radio")
                                .Add(DeliveryForm.Pocztex, "Kurier Pocztex pobranie (+1zł)")
                                .Add(DeliveryForm.PersonalWithoutTraining, [
                                    "Odbiór osobisty bez szkolenia (+1zł)",
                                    new Select(place, ["Warszawa/Wola", "Warszawa/Mokotów"]).MarginLeft(12).WidthAuto()], x => { x.Label.MarginLeft(0) })
                                .Add(DeliveryForm.PersonalWithTraining, "Odbiór osobisty ze szkoleniem Warszawa/Wola (+51zł)")
                                .Add(DeliveryForm.Inpost, "Paczkomat Inpost (+1zł)", x => x.Disabled())
                                .Add(DeliveryForm.Rentomat, "Rentomat (+1zł)", x => x.Disabled())
                        ),
                        new Line(),
                        new InputRow("Płatność",
                            new Radio(paymentForm).Class("radio")
                                .Add(PaymentForm.Cash, "Gotówka (cała opłata przy odbiorze)", x => x.Enable(allowCashPayment))
                                .Add(PaymentForm.DepositPrepaidServicePostpaid, "Całościowa przedpłata kaucji przelewem, reszta przy odbiorze")
                                .Add(PaymentForm.PartialDepositPrepaidServicePostpaid, "Częściowa przedpłata kaucji przelewem, reszta przy odbiorze")
                                .Add(PaymentForm.FromPreviousOrder, "Kaucja z poprzedniego zamówienia, płatność za usługę przy odbiorze")
                                .Add(PaymentForm.Crypto, "Bitcoin (całość z góry, 10% rabatu)")
                                .Add(PaymentForm.Transfer, "Przelew (całość z góry)", x => x.Disabled())
                                .Visible(this.basket.IsAnythingToRentRef, "flex"),
                            new Radio(paymentForm).Class("radio")
                                .Add(PaymentForm.Cash, "Gotówka", x => x.Enable(allowCashPayment))
                                .Add(PaymentForm.CashAtDelivery, "Gotówka przy odbiorze, płatne u kuriera", x => x.Disable(allowCashPayment))
                                .Add(PaymentForm.Crypto, "Bitcoin (całość z góry, 10% rabatu)")
                                .Add(PaymentForm.Transfer, "Przelew (całość z góry)", x => x.Disabled())
                                .VisibleWhen(this.basket.IsAnythingToRentRef, v => !v, "flex")
                        ),
                        new Line().Visible(this.basket.IsAnythingToRentRef),
                        new InputRow("💰 Kaucja", new Label(depositInfo))
                            .Visible(basket.IsAnythingToRentRef, "flex"),
                        new Line(),
                        new InputRow("🎓 Szkolenie", new Label(trainingInfo)),
                    ]),
                    new Section("📦 Zwrot", [
                        new InputRow("Forma",
                            new Radio(returnForm).Class("radio")
                                .Add(ReturnForm.Package, "Przesyłka Inpost (na koszt nadawcy)")
                                .Add(ReturnForm.Personal, "Zwrot osobisty (bez kosztów)")
                                .Add(ReturnForm.Taxi, "Taxi (na koszt nadawcy; tylko w Warszawie)")
                                .Add(ReturnForm.Rentomat, "Rentomat (bez kosztów)", x => x.Disabled())
                        ),
                        new Line(),
                        new InputRow("Zwrot kaucji",
                            new Label(depositReturnInfo)
                                .VisibleWhen(showCustomerDepositAccountInput, v => !v),
                            new TextInput().Placeholder("Numer konta do zwrotu kaucji")
                                .Visible(showCustomerDepositAccountInput),
                        ),
                    ]).Visible(this.basket.IsAnythingToRentRef, "block"),
                    new Section("🤵 Twoje dane", [
                        new InputRow("Imię i nazwisko", new TextInput(customerName)),
                        new InputRow("Telefon", new TextInput(customerPhone)),
                        new InputRow("Email", new TextInput(customerEmail)),
                        new InputRow("Adres", new TextInput(customerAddress).Placeholder("Ulica, numer budynku, numer mieszkania, kod pocztowy i miasto")),
                        new InputRow("Doświadczenie", new Select(customerExp, {
                            // "Nie powiem": 0,
                            "Jestem osobą totalnie atechniczną": 1,
                            "Radzę sobie z technologią": 2,
                            "Znam podobny sprzęt": 3,
                            "Znam Wasz sprzęt": 4
                        })),
                    ]),
                    new Section("🎈 Rabaty", [
                        new InputRow("Kod rabatowy", new TextInput().Placeholder("Wpisz jeśli jakiś posiadasz")),
                    ]),
                    new Line(),
                    new Center(
                        new Button("Przejdź do podsumowania").OnClick(async () =>
                        {
                            summary.Clear()
                            dynamicBasket.Items.ForEach(x =>
                                summary.Add(new Cost(x.Label.value + ": " + x.Name + " ×" + x.Quantity.value, x.Cost.value))
                            )

                            let depositsSum = dynamicBasket.GetDepositsSum();
                            if (depositsSum)
                                summary.Add(new ReturnableCost("Kaucja zwrotna", depositsSum))

                            if (deliveryForm.IsIn([DeliveryForm.Pocztex, DeliveryForm.Inpost, DeliveryForm.PersonalWithoutTraining]))
                                summary.Add(new Cost("Dostawa", 1))

                            if (deliveryForm.Is(DeliveryForm.PersonalWithTraining))
                                summary.Add(new Cost("Szkolenie z obsługi", 50))

                            if (paymentForm.Is(PaymentForm.Crypto))
                            {
                                const cryptoDiscount = summary.NonReturnableCostsSum * 0.1;
                                summary.Add(new Discount("Zniżka za płatność w Bitcoin", cryptoDiscount))
                            }

                            view.value = "summary";
                        }).Class("selected")
                    ).Margin(32)
                ))
                .AddContent("summary", () =>
                {
                    const returnToEditBtn = new PrimaryButton("Wróć do edycji").OnClick(async () =>
                    {
                        view.value = "form";
                    }).MarginLeft(8)

                    const loader = new Loader("Wysyłanie...").DisplayInline().Hide()

                    return new Div().Append(
                        new Section("🧮 Podsumowanie kosztów",
                            [
                                new Table([],
                                    [
                                        ...summary.Costs.map(x => ([x.Label, x.Value.toFixed(2) + " zł"])),
                                        ...summary.Discounts.map(x => ([x.Label, `- ${x.Value.toFixed(2)} zł`])),
                                    ]
                                ).Class("uk-table").WidthPercent(100).WidthAuto().MarginAuto().TextAlignLeft(),
                                new Markdown(`Łącznie do zapłaty: **${summary.Sum.toFixed(2)} zł**`)
                                    .DisplayBlock().Margin(0).MarginTop(24).MarginLeft(16),
                                basket.IsAnythingToRent && new Markdown(`Kaucja do zwrotu po oddaniu sprzętu: **${summary.ToReturn.toFixed(2)} zł**`)
                                    .DisplayBlock().Margin(0).MarginLeft(16),
                            ]),
                        new Section(basket.IsAnythingToRent ? "🚚 Dostawa i zwrot" : "🚚 Dostawa", [
                            new InputRow("Dostawa", DeliveryFormValueToLabel[deliveryForm.value]),
                            basket.IsAnythingToRent && new InputRow("Zwrot", returnForm.value),
                        ]),
                        basket.IsAnythingToRent && new Section("💰 Kaucja", [
                            new InputRow("Wpłata", depositInfo.value),
                            new InputRow("Zwrot", depositReturnInfo.value),
                        ]),
                        new Section("👦 Twoje dane", [
                            new InputRow("Imię i nazwisko", customerName.value),
                            new InputRow("Kontakt", customerPhone.value, ", ", customerEmail.value),
                            new InputRow("Adres", customerAddress.value),
                        ]),
                        new Line(),
                        new Center(
                            new DangerButton("Wyślij zgłoszenie").OnClick(async (btn) =>
                            {
                                btn.Disabled()
                                returnToEditBtn.Hide()
                                loader.Show()

                                summary.OrderId = uuidv4()
                                summary.Customer.Name = customerName.value;
                                summary.Customer.Phone = customerPhone.value;
                                summary.Customer.Email = customerEmail.value;
                                summary.Customer.Address = customerAddress.value;
                                summary.Customer.Experience = customerExp.value;
                                summary.Delivery = deliveryForm.value;
                                summary.Return = returnForm.value;
                                summary.PaymentForm = paymentForm.value;

                                console.log(summary)

                                let timeout;
                                try
                                {
                                    timeout = setTimeout(() =>
                                    {
                                        view.value = "sending-fault";
                                    }, 2 * 1000)

                                    await this._orders.Send(summary)

                                    view.value = "sent";
                                }
                                catch (error)
                                {
                                    view.value = "sending-fault";
                                }
                                finally
                                {
                                    clearTimeout(timeout);
                                }

                                returnToEditBtn.Hide()
                                loader.Hide()
                            }),
                            loader,
                            returnToEditBtn,
                        ).Margin(32),
                    )
                })
                .AddContent("sent", () =>
                {
                    basket.Clear();

                    setTimeout(() =>
                    {
                        window.location.href = "http://localhost:4339/#order/" + summary.OrderId;
                    }, 3000)

                    return new Center().Margin(32)
                        .Append("Zgłoszenie zostało wysłane. Za chwilę nastąpi przekierowanie na stronę zamówienia...");
                })
                .AddContent("sending-fault", () =>
                {
                    return new Center().Margin(32)
                        .Append("Nie udało się wysłać zgłoszenia. Spróbuj ponownie za chwile.\n\n"
                            , new Link("Wyślij ponownie").OnClick(() => view.value = "summary")
                        )
                }),
        )
    }
}


export class DangerButton extends Button
{
    constructor(label, action?)
    {
        super(label, action);
        this.DisplayInlineBlock();
        this.Class("uk-button uk-button-danger").Style("white-space: nowrap");
    }
}
export class PrimaryButton extends Button
{
    constructor(label, action?: (self: PrimaryButton) => void)
    {
        super(label, action);
        this.DisplayInlineBlock();
        this.Class("uk-button uk-button-primary");
        this.Style("white-space: nowrap");
    }
}
