import { Div, CollectionPrinter, Link, Line, Button, Snack } from "@tblabs/truffle";
import { Header } from "../Components/Utils/Header";
import { Row, Left } from "../Components/Utils/Row";
import { BlogArticle } from "../Models/WebsiteContent";
import { AuthService } from "../Services/Auth/AuthService";
import { WebsiteContentProvider } from "../Services/Content/WebsiteContentProvider";
import { Loader } from "../Components/Loader";
import { BlogEntry } from "../Components/BlogEntry";
import { DescriptionEditor } from "./DescriptionEditor";
import { FoldableSection } from "../Components/Section";


export class PageEditPage extends Div
{
    private loader = new Loader();
    private content = new Div();

    constructor(private _content: WebsiteContentProvider, private _auth: AuthService)
    {
        super();

        this.Append(
            this.loader,
            this.content
        )
    }

    protected async OnAppend(): Promise<void>
    {
        if (!this._auth.IsAuthorized)
        {
            this.Append(`Brak autoryzacji`);
            return;
        }

        const content = await this._content.Get();

        this.loader.RemoveSelf();

        this.content.Append(
            new Row(
                new FoldableSection("O nas", [
                    new DescriptionEditor(content.AboutUs),
                ]),
                new FoldableSection("Kontakt", [
                    new DescriptionEditor(content.Contact), 
                ]),
                new FoldableSection("Blog", [
                    new CollectionPrinter(content.Blog, x => new BlogEntry(x, content.Blog)),
                    new Left(
                        new Link("➕ Artykuł").OnClick(() => content.Blog.Add(new BlogArticle({})))
                    ),
                ]),
                new Line(),
                new Button("Zapisz").Class("selected")
                    .OnClick(async () =>
                    {
                        const result = await this._content.Update(content);
                        new Snack(result.IsSuccess ? "Zmiany zapisane!" : "❌ Problem z zapisaniem zmian", result.ErrorMessage || (result.ContentSize.toFixed(2) + "kb"));
                        // window.location.hash = `/`;
                        history.back();;
                    })
            )
        )
    }
}
