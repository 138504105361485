import { UserInfo } from "./UserInfo";


export class UserLocalStorage
{
    public Save(userInfo: UserInfo): void
    {
        try
        {
            window.localStorage.setItem('user', JSON.stringify(userInfo));
        }
        catch (ex: any)
        {
            console.warn(`Storing user info to local storage problem: ${ex.message}`);
        }
    }

    public Delete(): void
    {
        window.localStorage.removeItem('user');
    }

    public get IsUserAvailable(): boolean
    {
        const raw = window.localStorage.getItem('user');

        return raw != null;
    }

    public get User(): UserInfo
    {
        const rawString = window.localStorage.getItem('user');
        const raw = JSON.parse(rawString || "");

        const userInfo = new UserInfo();
        userInfo.User.Id = raw?.User?.Id || "";
        userInfo.User.Name = raw?.User?.Name || "";
        userInfo.App.WebsiteContentStorageAccessToken = raw?.App?.WebsiteContentStorageAccessToken || "";

        return userInfo;
    }
}
