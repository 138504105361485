import { RefString } from "@tblabs/truffle";
import { RawTableItem } from "./RawTableItem";


export class TableItem
{
    public Left = new RefString();
    public Right = new RefString();

    constructor(raw: Partial<RawTableItem>)
    {
        this.Left.value = raw.Left || "";
        this.Right.value = raw.Right || "";
    }
}
