import { appendable } from "@tblabs/truffle/Core/appendable";
import { Row } from "./Row";


export class Header extends Row
{
    constructor(...cmps: appendable[])
    {
        super(...cmps);
        this.Class("header")

        this.Content
            .TextAlignLeft()
            .FontSize(18)
            .BorderBottom(1, "#aaa")
            .Bold()
    }
}
