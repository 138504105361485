"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Collection = void 0;
var Ref_1 = require("./Ref");
var RefBase_1 = require("./RefBase");
var Collection = /** @class */ (function (_super) {
    __extends(Collection, _super);
    function Collection(items) {
        if (items === void 0) { items = []; }
        var _this = _super.call(this) || this;
        _this.items = items;
        _this.CountRef = new Ref_1.Ref(_this.items.length);
        return _this;
    }
    Object.defineProperty(Collection.prototype, "IsEmpty", {
        // public Storable(
        //     key: string | undefined,
        //     storage: "local" | "session" = "local",
        //     serialize: (items: T[]) => string = (items) => JSON.stringify(items),
        //     deserialize: (serialized: string) => T[] = (serialized) => JSON.parse(serialized)): this
        // {
        //     if (!key)
        //     {
        //         return this;
        //     }
        //     let stor;
        //     switch (storage)
        //     {
        //         case "local": stor = window.localStorage; break;
        //         case "session": stor = window.sessionStorage; break;
        //     }
        //     const valueFromStorage = stor.getItem(key);
        //     if (valueFromStorage)
        //     {
        //         this.items = deserialize(valueFromStorage);
        //     }
        //     this.OnChange(items =>
        //     {
        //         stor.setItem(key, serialize(items))
        //     })
        //     return this;
        // }
        get: function () {
            return this.Count == 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Collection.prototype, "IsNotEmpty", {
        get: function () {
            return this.Count > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Collection.prototype, "Items", {
        get: function () {
            return this.items;
        },
        enumerable: false,
        configurable: true
    });
    Collection.prototype.Add = function () {
        var _this = this;
        var newItems = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            newItems[_i] = arguments[_i];
        }
        newItems.forEach(function (x) {
            _this.items.push(x);
        });
        this.CountRef.value = this.items.length;
        this.onChangeHandler.CallHandlers(this.items);
        this.RefreshSubscribers();
        return this;
    };
    Collection.prototype.Load = function () {
        // TODO: jeśli pierwszy argument jest tablicą to traktuj jak tablice, tylko co z tablicą tablic wtedy?
        var items = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            items[_i] = arguments[_i];
        }
        this.items = [];
        return this.Add.apply(this, items);
    };
    Collection.prototype.Remove = function (item) {
        if (item == undefined)
            return this;
        return this.Call(function (items) { return items.Remove(item); });
    };
    Collection.prototype.RemoveAll = function () {
        return this.Modify(function (items) { return []; });
    };
    Collection.prototype.Clear = function () {
        return this.RemoveAll();
    };
    Collection.prototype.Contains = function (item) {
        return this.items.includes(item);
    };
    Collection.prototype.Modify = function (fn) {
        this.items = fn(this.items);
        this.CountRef.value = this.items.length;
        this.onChangeHandler.CallHandlers(this.items);
        this.RefreshSubscribers();
        return this;
    };
    Collection.prototype.ModifyOne = function (predicate, modify) {
        var found = this.items.find(predicate);
        if (!found)
            return this;
        modify(found);
        this.CountRef.value = this.items.length;
        this.onChangeHandler.CallHandlers(this.items);
        this.RefreshSubscribers();
        return this;
    };
    Collection.prototype.Call = function (fn) {
        fn(this.items);
        this.CountRef.value = this.items.length;
        this.onChangeHandler.CallHandlers(this.items);
        this.RefreshSubscribers();
        return this;
    };
    Collection.prototype.ForceOnChange = function () {
        this.CountRef.value = this.items.length;
        this.onChangeHandler.CallHandlers(this.items);
        this.RefreshSubscribers();
        return this;
    };
    Collection.prototype.ForEach = function (callback) {
        this.items.forEach(function (x) {
            callback(x);
        });
        return this;
    };
    Collection.prototype.Filter = function (callback) {
        return this.items.filter(function (x, index, array) { return callback(x, index, array); });
    };
    // public Map<TT>(callback: (item: T, index: number, array: T[]) => void): TT[]
    // {
    //     return this.items.map<TT>((x, index, array) => callback(x, index, array));
    // }
    Collection.prototype.RemoveMany = function (items) {
        var _this = this;
        items.forEach(function (x) {
            _this.items.Remove(x);
        });
        this.CountRef.value = this.items.length;
        this.onChangeHandler.CallHandlers(this.items);
        this.RefreshSubscribers();
        return this;
    };
    Object.defineProperty(Collection.prototype, "Count", {
        get: function () {
            return this.items.length;
        },
        enumerable: false,
        configurable: true
    });
    Collection.prototype.Take = function (start, count) {
        if (count > 0)
            return this.items.slice(start, start + count);
        else
            return this.items.slice(start);
    };
    Collection.prototype.Swap = function (a, b) {
        var _a;
        if (a < 0 || b >= this.items.length) {
            return this;
        }
        _a = [this.items[b], this.items[a]], this.items[a] = _a[0], this.items[b] = _a[1];
        this.CountRef.value = this.items.length;
        this.onChangeHandler.CallHandlers(this.items);
        this.RefreshSubscribers();
        return this;
    };
    Collection.prototype.SwapUp = function (item) {
        var itemIndex = this.items.findIndex(function (x) { return x == item; });
        if (itemIndex == (-1)) {
            return this;
        }
        var itemAbove = itemIndex - 1;
        if (itemAbove < 0) {
            return this;
        }
        this.Swap(itemIndex, itemAbove);
        return this;
    };
    Collection.prototype.SwapDown = function (item) {
        var itemIndex = this.items.findIndex(function (x) { return x == item; });
        if (itemIndex == (-1)) {
            return this;
        }
        var itemBelow = itemIndex + 1;
        if (itemBelow >= this.Count) {
            return this;
        }
        this.Swap(itemIndex, itemBelow);
        return this;
    };
    Collection.prototype.CanSwapUp = function (item) {
        return !this.IsFirst(item);
    };
    Collection.prototype.CanSwapDown = function (item) {
        return !this.IsLast(item);
    };
    Collection.prototype.IsFirst = function (item) {
        var itemIndex = this.items.findIndex(function (x) { return x == item; });
        return itemIndex == 0;
    };
    Collection.prototype.IsLast = function (item) {
        var itemIndex = this.items.findIndex(function (x) { return x == item; });
        return itemIndex == this.Count - 1;
    };
    Object.defineProperty(Collection.prototype, "FirstItem", {
        get: function () {
            return this.items[0];
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Collection.prototype, "LastItem", {
        get: function () {
            return this.items[this.Count - 1];
        },
        enumerable: false,
        configurable: true
    });
    Collection.prototype.OnChange = function (handler, callThisCallbackRightAway) {
        if (callThisCallbackRightAway === void 0) { callThisCallbackRightAway = false; }
        this.onChangeHandler.AddHandler(handler);
        if (callThisCallbackRightAway == true) {
            handler(this.items, true);
        }
        return this;
    };
    Collection.prototype.toString = function () {
        if (this.Name)
            return "Collection of ".concat(this.Name, " (").concat(this.Count, " items)");
        else
            return "Collection (".concat(this.Count, " items)");
    };
    return Collection;
}(RefBase_1.RefBase));
exports.Collection = Collection;
