import { Div, Select, Span, TextInput } from "@tblabs/truffle";
import { appendable } from "@tblabs/truffle/Core/appendable";


export class InputRow extends Div
{
    constructor(label: string, ...content: appendable[])
    {
        super("InputRow");

        if (!Array.isArray(content))
        {
            content = [content];
        }

        const labelPlace = new Div("fixed-width").Text(label)

        if (content[0] instanceof TextInput || content[0] instanceof Select)
            labelPlace.Padding(8)

        this.Append(
            labelPlace,
            new Div("flexible-width").Append(...content)
        )
    }
}
