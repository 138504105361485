import { FaqDescriptionField } from "../../Components/Faq/FaqDescriptionField";
import { GalleryDescriptionField } from "../../Components/Gallery/GalleryDescriptionField";
import { MarkdownDescriptionField } from "../../Components/Markdown/MarkdownDescriptionField";
import { TableDescriptionField } from "../../Components/Table/TableDescriptionField";
import { TextDescriptionField } from "../../Components/TextDescriptionField";
import { DescriptionField } from "../../Models/DescriptionField";
import { DescriptionFieldType } from "../../Models/DescriptionFieldType";
import { RawDescriptionField } from "../../Models/RawDescriptionField";
import { RawFaqDescriptionField } from "../../Models/RawFaqDescriptionField";
import { RawGalleryDescriptionField } from "../../Models/RawGalleryDescriptionField";
import { RawMarkdownDescriptionField } from "../../Models/RawMarkdownDescriptionField";
import { RawTableDescriptionField } from "../../Models/RawTableDescriptionField";
import { RawTextDescriptionField } from "../../Models/RawTextDescriptionField";


export class DescriptionFieldConverter
{
    public static ToClass(raw: RawDescriptionField): DescriptionField
    {
        switch (raw.Type as DescriptionFieldType)
        {
            case DescriptionFieldType.Markdown: return new MarkdownDescriptionField(raw as RawMarkdownDescriptionField);
            case DescriptionFieldType.Faq: return new FaqDescriptionField(raw as RawFaqDescriptionField);
            case DescriptionFieldType.Gallery: return new GalleryDescriptionField(raw as RawGalleryDescriptionField);
            case DescriptionFieldType.Table: return new TableDescriptionField(raw as RawTableDescriptionField);
            case DescriptionFieldType.Text: return new TextDescriptionField(raw as RawTextDescriptionField);
            // default: throw new Error(`Unknown "${x.Type}" description field type`)
        }
    }
}
