import { Span } from "@tblabs/truffle";
import { Table } from "@tblabs/truffle/Controls/TableBuilder/Table";
import { AdminEditButton } from "../Components/AdminEditButton";
import { Loader } from "../Components/Loader";
import { PageLogo } from "../Components/PageTop";
import { Header } from "../Components/Utils/Header";
import { Markdown } from "../Components/Utils/Markdown";
import { Row } from "../Components/Utils/Row";
import { AuthService } from "../Services/Auth/AuthService";
import { WebsiteContentProvider } from "../Services/Content/WebsiteContentProvider";
import { Page } from "./Page";
import { DescriptionFieldBox } from "../Components/DescriptionFieldBox";


export class ContactPage extends Page
{
    private loader = new Loader();
    private content = new Row();

    constructor(private _content: WebsiteContentProvider, private _user: AuthService)
    {
        super();

        this.body.Append(
            new PageLogo(),
            this.loader,
            this.content
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._content.Get();

        this.loader.RemoveSelf();

        this.content.Insert(
            // new Header(
            //     "Kontakt z nami",
            // this._user.IsAuthorized && new AdminEditButton(`edit/page`)
            // ),
            // new Table([],
            //     [
                //         ...content.Contact.Items.map(x => ([new Span(x.Form.value).Bold(), new Markdown(x.Details.value)]))
                //     ]
                // ).Class("uk-table").MarginAuto().WidthAuto().TextAlignLeft()
            this._user.IsAuthorized && new AdminEditButton(`edit/page`),
            ...content.Contact.Items.map(entry => new DescriptionFieldBox(entry)),
        )
    }
}
