import { Message } from "./Message";


export class UpdateCommand extends Message
{
    constructor(
        public Id: string,
        public Folder: string,
        public Content: object,
        public CreateIfNotExist: boolean)
    {
        super("Update", { Id, Folder, Content, CreateIfNotExist });
    }
}
