import { Button, Div, Image, Label, Line, Link, RefBool, Span, TextInput } from "@tblabs/truffle";
import { Row } from "./Utils/Row";
import { Basket } from "../Services/Basket/Basket";
import { MiniBasketBox } from "./MiniBasketBox";
import { Center } from "./Utils/Center";


export class PageHeader extends Div
{
    protected body = new Div();

    constructor(_basket: Basket)
    {
        super("page-header");

        const showMiniBasket = new RefBool();

        this.body.Append(
            new Link("Oferta").Class("link").FloatLeft().OnClick(() => window.location.hash = "/"),
            new Link("Kontakt").Class("link").FloatLeft().OnClick(() => window.location.hash = "kontakt"),
            new Link("O nas").Class("link").FloatLeft().OnClick(() => window.location.hash = "o-nas"),
            // new Link("Blog").Class("link").FloatLeft().OnClick(() => window.location.hash = "blog"),
            new Span("☎️ (+48) 507 293 714").Color("#fff").OnClick(() => window.location.hash = "kontakt"),
            new Label(_basket.Products.CountRef, v => `🛒 Koszyk (${v})`).FloatRight().Color("#f8f8f8").CursorPointer()
                .OnClick(() => showMiniBasket.Toggle()),
            new Div().Style(`position: absolute; top: 38px; right: 0px; z-index: 100; background: #f6f6f6; color: #333; height: auto; border: 2px solid #ccc; text-align: left; padding: 16px;`).Visible(showMiniBasket)
                .Append(
                    new Span("🛒 Koszyk").Italic().FontSize(24),
                    new Link("✖️").Style(`position: absolute; top: 4px; right: 4px;`)
                        .OnClick(() => showMiniBasket.False()),
                    new MiniBasketBox(_basket),
                    new Line(),
                    new Center(
                        // new Span("Koszt wypożyczenia jest zależny od jego terminów, cenę można policzyć w następnym kroku...").Italic(),
                        new Button("Zobacz koszyk").Class("selected").Margin(8)
                            .EnableWhen(_basket.Products.CountRef, c => +c > 0)
                            .OnClick(() =>
                            {
                                showMiniBasket.False();
                                window.location.hash = "basket";
                            })
                    )
                )
        )
        this.Append(this.body)
    }
}

export class PageLogo extends Div
{
    constructor()
    {
        super();

        this.Append(
            new Row(
                new Image("https://www.specteam.pl/img/logo_black.png") // TODO: zmienić na photos.specteam.pl
                    .FloatLeft().MarginLeft(80).CursorPointer()
                    .OnClick(() => window.location.hash = "/"),
                new Div().DisplayInlineBlock().Text("WYPOŻYCZALNIA\nSPRZĘTU\nDETEKTYWISTYCZNEGO")
                    .OnClick(() => window.location.hash = "/")
                    .FloatLeft().CursorPointer()
                    .MarginTop(16).MarginLeft(16).TextAlignLeft().Bold().Italic().FontSize(20),
                new TextInput().Placeholder("🔎 Szukaj")
                    .Width(220).PositionAbsolute().Top(40).Right(47)
            )
        )
    }
}
