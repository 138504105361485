
export enum DeliveryForm
{
    Unset = "Unset",
    Pocztex = "Pocztex",
    Inpost = "Inpost",
    PersonalWithTraining = "PersonalWithTraining",
    PersonalWithoutTraining = "PersonalWithoutTraining",
    Rentomat = "Rentomat"
}

export const DeliveryFormLabelToValue = {
    "Kurier Pocztex pobranie (1zł)": DeliveryForm.Pocztex,
    "Odbiór osobisty bez szkolenia (+1zł)": DeliveryForm.PersonalWithoutTraining,
    "Odbiór osobisty ze szkoleniem (+51zł)": DeliveryForm.PersonalWithTraining,
    "Paczkomat Inpost (1zł)": DeliveryForm.Inpost,
    "Rentomat": DeliveryForm.Rentomat,
}

export const DeliveryFormValueToLabel = {
    [DeliveryForm.Pocztex]: "Kurier Pocztex pobranie",
    [DeliveryForm.PersonalWithoutTraining]: "Odbiór osobisty bez szkolenia",
    [DeliveryForm.PersonalWithTraining]: "Odbiór osobisty ze szkoleniem",
    [DeliveryForm.Inpost]: "Paczkomat Inpost",
    [DeliveryForm.Rentomat]: "Rentomat",
}