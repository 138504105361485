import { Div, RefBool, Collection, Span, Link, TextInput, Checkbox } from "@tblabs/truffle";
import { appendable } from "@tblabs/truffle/Core/appendable";
import { DescriptionField } from "../Models/DescriptionField";


export class FieldEditor extends Div
{
    private expand = new RefBool(true).Storable(`${this.type}:${this.item.Header.value}`);

    constructor(private type: string, private item: DescriptionField, collection: Collection<DescriptionField>, content: appendable[] = [])
    {
        super();
        this.Background("#f8f8f8")
            .Border(4, "#333")
            .BorderRadius(8)
            .Padding(0)
            .MarginBottom(12);

        this.Append(
            new Div().Padding(4).Background("#333").Color("#eee").Append(
                new Span(type).FloatLeft(),
                new Link("👀Pokaż/ukryj").MarginLeft(12).FloatLeft()
                    .OnClick(() => this.expand.Toggle()),
                new TextInput(item.Header).Placeholder("Tytuł sekcji").WidthAuto().MarginLeft(12).FloatLeft(),
                new Checkbox(item.VisibleHeader, "Widoczny tytuł").MarginLeft(12).FloatLeft(),
                new Link("✖️").OnClick(() => collection.Remove(item)).FloatRight(),
                collection.CanSwapUp(item) && new Link("⬆️").FloatRight()
                    .OnClick(() => collection.SwapUp(item)),
                collection.CanSwapDown(item) && new Link("⬇️").FloatRight()
                    .OnClick(() => collection.SwapDown(item)),
                new Div().ClearBoth()
            ),
            new Div().WidthPercent(100).Append(
                ...content
            ).Visible(this.expand),
            new Div().ClearBoth()
        );
    }
}
