import { Div, Link } from "@tblabs/truffle";
import { HashLink } from "../Components/Utils/HashLink";


export class PageFooter extends Div
{
    constructor()
    {
        super("page-footer");

        this.Append(
            new HashLink("Zaloguj", "admin/login"),
            new HashLink("Blog", "blog"),
            new HashLink("Rekrutacja/praca", ""),
        )
    }
}
