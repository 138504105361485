import { Div, RefBool, Link } from "@tblabs/truffle";
import { Center } from "../Utils/Center";
import { FaqDescriptionField } from "./FaqDescriptionField";
import { FaqEditEntry } from "./FaqEditEntry";


export class FaqPrinter extends Div
{
    constructor(field: FaqDescriptionField)
    {
        super();
        this.TextAlignLeft();

        const expand = new RefBool();

        this.Append(
            ...field.Questions.Take(0, 3).map(x => new FaqEditEntry(x)),
            (field.Questions.Count > 3) && new Center(new Link(`Pokaż wszystkie ${field.Questions.Count}...`).OnClick(_ => { _.Hide(); expand.True(); })).Margin(32),
            new Div().Append(...field.Questions.Take(0, 3).map(x => new FaqEditEntry(x))).Visible(expand)
        )
    }
}
