import { RefString } from "@tblabs/truffle";
import { DescriptionField } from "../../Models/DescriptionField";
import { DescriptionFieldType } from "../../Models/DescriptionFieldType";
import { RawMarkdownDescriptionField } from "../../Models/RawMarkdownDescriptionField";


export class MarkdownDescriptionField extends DescriptionField
{
    public Type = DescriptionFieldType.Markdown;

    public Markdown = new RefString();

    constructor(raw: Partial<RawMarkdownDescriptionField>)
    {
        super(raw);

        this.Markdown.value = raw.Markdown || "";
    }
}
