import { Header } from "../Components/Utils/Header";
import { FeaturesInfo } from "../Components/RentalInfo";
import { Page } from "./Page";
import { HashLink } from "../Components/Utils/HashLink";
import { ProductBox } from "../Components/ProductBox";
import { WebsiteContentProvider } from "../Services/Content/WebsiteContentProvider";
import { Loader } from "../Components/Loader";
import { Row } from "../Components/Utils/Row";
import { PageLogo } from "../Components/PageTop";
import { AdminAddButton } from "../Components/AdminEditButton";


export class CategoryPage extends Page
{
    private loader = new Loader()
    private content = new Row()

    constructor(private _content: WebsiteContentProvider, private categoryUrl: string)
    {
        super();

        this.body.Append(
            new PageLogo(),
            new FeaturesInfo(),
            this.loader,
            this.content,
        )
    }

    protected async OnAppend(): Promise<void>
    {
        const content = await this._content.Get();
        const category = content.CategoryByUrl(this.categoryUrl);
        const products = content.ProductsByCategory(category);

        this.loader.RemoveSelf()

        this.content.Insert(
            new Header(new HashLink("Oferta"), " > ", category.Title.value),
            products.length == 0 && "Brak produktów w tej kategorii",
            ...products?.map(x => new ProductBox(x)) ,
            new AdminAddButton('add/product'),
        )
    }
}
