import { Div, RefString, Image, Collection } from "@tblabs/truffle";
import { GalleryImage } from "../../Models/GalleryImage";


export class Gallery extends Div
{
    constructor(images: Collection<GalleryImage>)
    {
        super("gallery");

        if (images.Count == 0)
        {
            this.Append("Brak zdjęć")
            return;
        }

        const previewPicture = new RefString(images.Items[0].ThumbnailUrl.value || images.Items[0].Url.value);
        const pictureUrl = new RefString(images.Items[0].Url.value);

        this.Append(
            new Image(previewPicture).Class("preview")
                .OnClick(() => window.open(pictureUrl.value, "_blank")),
            new Div("minis").Append(
                ...images.Items.map(x => new Image(x.ThumbnailUrl.value || x.Url.value, x.Description.value).Class("mini")
                    .OnClick(() =>
                    {
                        previewPicture.value = x.ThumbnailUrl.value || x.Url.value;
                        pictureUrl.value = x.Url.value;
                    })
                )
            )
        )
    }
}
